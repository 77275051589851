import * as Gtypes from '~/gapi/gtypes';

export const consortiumQuery = `{
  accountUuid
  accountName
  ownerEmail
  role
}`;

const providerQuery = `{
  value
  label
  region {
    value
    label
    images {
      value
      label
    }
  }
}`;

const hardForkProposalQuery = `{
  uuid
  accountUuid
  name
  periodTargetBlock
  blockNumber
  approvedNodeUuids
  rejectedNodeUuids
  signerNodeUuids
  nodeUuids
  explorerUuids
  appliedNodeUuids
  appliedExplorerUuids
  status
  createAt
}`;

export const listProvidersQuery = `query ($providerName: String) {
  listProviders(providerName: $providerName) ${providerQuery}
}`;

export type QueryListProvidersArgs = Gtypes.QueryListProvidersArgs;

export type LIST_PROVIDERS_RESULT_TYPE = {
  listProviders: Gtypes.Provider[];
};

export const genesisConfigQuery = `{
  chainId
  homesteadBlock
  eip150Block
  eip150Hash
  eip155Block
  eip158Block
  byzantiumBlock
  constantinopleBlock
  petersburgBlock
  istanbulBlock
  muirGlacierBlock
  berlinBlock
  londonBlock
  clique {
    period
    epoch
  }
}`;

export const planningHardForkQuery = `{
  proposalId
  startAt
  estimateEndAt
}`;

export const proposalAddressInfoQuery = `{
  address
  node {
    accountUuid
    clusterUuid
    clusterName
    nodeUuid
    nodeName
  }
}`;

export const networkSnapshotQuery = `{
  number
  signers ${proposalAddressInfoQuery}
  proposals {
    authorize
    target ${proposalAddressInfoQuery}
    approved ${proposalAddressInfoQuery}
  }
  votes {
    authorize
    to ${proposalAddressInfoQuery}
    from ${proposalAddressInfoQuery}
  }
}`;

export const nodeQuery = `{
  nodeUuid
  nodeName
  nodeType
  nodeInfo {
    program
    version
    signer
    signerStatus
    enode
    status
    coinbasePrivateKey
    coinbaseAddress
    peerCount
    latestBlock
    initGasPrice
    balanceGasPrice
    balanceEth
    balanceWei
    gcMode
    blockGasLimit
    syncing
    genesisConfig ${genesisConfigQuery}
    allowUnprotectedTxs
    localProposals {
      address
      authorize
    }
  }
  serverType
  serverInfo {
    az
    ip
    status
    instanceId
    instanceImage
    instanceType
    publicDns
    systemSize
    systemUsed
    volumeSize
    volumeUsed
  }
  signerInfo {
    externalClef {
      username
      host
      port
      status
    }
  }
}`;

const nodeParentQuery = nodeQuery.replace('nodeUuid', 'networkUuid\n  clusterUuid\n  nodeUuid');

export const explorerQuery = `{
  explorerUuid
  nodeInfo {
    version
    enode
    latestBlock
    genesisConfig ${genesisConfigQuery}
  }
  serverInfo {
    az
    ip
    status
    instanceId
    instanceImage
    instanceType
    explorerType
    endpointUrl
    systemSize
    systemUsed
    volumeSize
    volumeUsed
  }
  blockscoutInfo {
    backend {
      version
    }
    frontend {
      version
    }
    rpcUrl
  }
}`;
const customDomainQuery = `{
  certArn
  domainName
  status
}`;

const customDomainDetailQuery = `{
  certArn
  domainName
  cname
  cnameValue
  status
  isCertificateAddEndpoint
  endpointDnsName
  customDomainUrl
}`;

export const clusterQuery = `{
  accountUuid
  accountName
  clusterUuid
  clusterName
  clusterType
  endpointId
  endpointUrl
  provider
  region
  status
  nodes ${nodeQuery}
  customDomains ${customDomainDetailQuery}
  explorer ${explorerQuery}
  listeners {
    type
    port
    inbound {
      cidr
      desc
    }
  }
}`;

const clusterParentQuery = clusterQuery.replace('accountUuid', 'networkUuid\n  accountUuid');

export const networkOptionQuery = `{
  enableDeadlockResolver
}`;

export const networkQuery = `{
  networkId
  chainId
  networkUuid
  networkName
  networkDescription
  role
  clusters ${clusterQuery}
  consortiums ${consortiumQuery}
  options ${networkOptionQuery}
  blockchainInfo {
    networkId
    chainId
    blockInterval
    blockGasLimit
    allocAddress
    allocBalance
    signerAddresses
    defaultGasPrice
    currencyName
    currencySymbol
    genesisConfig ${genesisConfigQuery}
    epochBlock
  }
  planningHardFork ${planningHardForkQuery}
  networkSnapshot ${networkSnapshotQuery}
}`;

const networkAccessQuery = `{
  uid
  displayName
  email
  hasProfile
  networkRole
  accountRole
  createAt
  updateAt
  lastloginAt
}`;

export const networkInvitationQuery = `{
  invitationUuid
  networkUuid
  networkName
  senderAccountUuid
  senderAccountName
  senderUid
  senderEmail
  receiverAccountUuid
  receiverAccountName
  receiverUid
  receiverEmail
  status
  createAt
  updateAt
}`;

export const proposalQuery = `{
  proposalUuid
  proposalType
  proposalStatus
  sender {
    accountUuid
    accountName
    nodeUuid
  }
  receivers {
    accountUuid
    accountName
    nodes {
      nodeUuid
      status
    }
  }
  createAt
  updateAt
}`;

export const getNetworkRoleQuery = `query ($accountUuid: String!, $networkUuid: String!) {
  getNetworkRole(accountUuid: $accountUuid, networkUuid: $networkUuid)
}`;

export type QueryGetNetworkRoleArgs = Gtypes.QueryGetNetworkRoleArgs;

export type GET_NETWORK_ROLE_RESULT_TYPE = {
  getNetworkRole: Gtypes.NetworkRoleType;
};

export const getNetworkQuery = `query ($networkUuid: String!, $accountUuid: String!) {
  getNetwork(networkUuid: $networkUuid, accountUuid: $accountUuid) ${networkQuery}
}`;

export type QueryGetNetworkArgs = Gtypes.QueryGetNetworkArgs;

export type GET_NETWORK_RESULT_TYPE = {
  getNetwork: Gtypes.Network;
};

export const listNetworksQuery = `query ($accountUuid: String!) {
  listNetworks(accountUuid: $accountUuid) ${networkQuery}
}`;

export type QueryListNetworksArgs = Gtypes.QueryListNetworksArgs;

export type LIST_NETWORKS_RESULT_TYPE = {
  listNetworks: Gtypes.Network[];
};

export const listNetworkAccessesQuery = `query ($accountUuid: String!, $networkUuid: String!) {
  listNetworkAccesses(accountUuid: $accountUuid, networkUuid: $networkUuid) ${networkAccessQuery}
}`;
export type QueryListNetworkAccessesArgs = Gtypes.QueryListNetworkAccessesArgs;
export type LIST_NETWORK_ACCESSES_RESULT_TYPE = {
  listNetworkAccesses: Gtypes.NetworkAccess[];
};

export const createNetworkMutation = `mutation ($input: CreateNetworkInput!) {
  createNetwork(input: $input) {
    status
    execAt
    data ${networkQuery}
  }
}`;

export type MutationCreateNetworkArgs = Gtypes.MutationCreateNetworkArgs;

export type CREATE_NETWORK_RESULT_TYPE = {
  createNetwork: Gtypes.CreateNetworkResult;
};

const updateNetworkQuery = `{
  networkUuid
  networkName
  defaultGasPrice
  networkDescription
  currencyName
  currencySymbol
}`;

export const updateNetworkMutation = `mutation ($input: UpdateNetworkInput!) {
  updateNetwork(input: $input) ${updateNetworkQuery}
}`;

export type MutationUpdateNetworkArgs = Gtypes.MutationUpdateNetworkArgs;

export type UPDATE_NETWORK_RESULT_TYPE = {
  updateNetwork: Gtypes.UpdateNetworkResponse;
};

export const destroyNetworkMutation = `mutation ($input: DestroyNetworkInput!) {
  destroyNetwork(input: $input)
}`;

export type MutationDestroyNetworkArgs = Gtypes.MutationDestroyNetworkArgs;

export type DESTROY_NETWORK_RESULT_TYPE = {
  destroyNetwork: boolean;
};

export const findClustersQuery = `query ($accountUuid: String!, $clusterUuids: [String!]!, $networkUuid: String!) {
  findClusters(accountUuid: $accountUuid, clusterUuids: $clusterUuids, networkUuid: $networkUuid) ${clusterParentQuery}
}`;

export type QueryFindClustersArgs = Gtypes.QueryFindClustersArgs;

export type FIND_CLUSTERS_RESULT_TYPE = {
  findClusters: Gtypes.ClusterParent[];
};

export const findNodesQuery = `query ($accountUuid: String!, $nodeUuids: [String!]!, $networkUuid: String!) {
  findNodes(accountUuid: $accountUuid, nodeUuids: $nodeUuids, networkUuid: $networkUuid) ${nodeParentQuery}
}`;

export type QueryFindNodesArgs = Gtypes.QueryFindNodesArgs;

export type FIND_NODES_RESULT_TYPE = {
  findNodes: Gtypes.NodeParent[];
};

export const createClusterMutation = `mutation ($input: CreateClusterInput!) {
  createCluster(input: $input) {
    status
    execAt
    data ${clusterQuery}
  }
}`;

export type MutationCreateClusterArgs = Gtypes.MutationCreateClusterArgs;

export type CREATE_CLUSTER_RESULT_TYPE = {
  createCluster: Gtypes.CreateClusterResult;
};

export const updateClusterMutation = `mutation ($input: UpdateClusterInput!) {
  updateCluster(input: $input) ${clusterQuery}
}`;

export type MutationUpdateClusterArgs = Gtypes.MutationUpdateClusterArgs;

export type UPDATE_CLUSTER_RESULT_TYPE = {
  updateCluster: Gtypes.Cluster;
};

export const deleteClusterMutation = `mutation ($input: DeleteClusterInput!) {
  deleteCluster(input: $input)
}`;

export type MutationDeleteClusterArgs = Gtypes.MutationDeleteClusterArgs;

export type DELETE_CLUSTER_RESULT_TYPE = {
  deleteCluster: boolean;
};

export const createNodeMutation = `mutation ($input: CreateNodeInput!) {
  createNode(input: $input) {
    status
    execAt
    data ${nodeQuery}
  }
}`;

export type MutationCreateNodeArgs = Gtypes.MutationCreateNodeArgs;

export type CREATE_NODE_RESULT_TYPE = {
  createNode: Gtypes.CreateNodeResult;
};

export const updateNodeMutation = `mutation ($input: UpdateNodeInput!) {
  updateNode(input: $input) ${nodeQuery}
}`;

export type MutationUpdateNodeArgs = Gtypes.MutationUpdateNodeArgs;

export type UPDATE_NODE_RESULT_TYPE = {
  updateNode: Gtypes.Node;
};

export const deleteNodeMutation = `mutation ($input: DeleteNodeInput!) {
  deleteNode(input: $input)
}`;

export type MutationDeleteNodeArgs = Gtypes.MutationDeleteNodeArgs;

export type DELETE_NODE_RESULT_TYPE = {
  deleteNode: boolean;
};

export const controlNodeMutation = `mutation ($input: ControlNodeInput!) {
  controlNode(input: $input)
}`;

export type MutationControlNodeArgs = Gtypes.MutationControlNodeArgs;

export type CONTROL_NODE_RESULT_TYPE = {
  controlNode: boolean;
};

export const controlBlockExplorerMutation = `mutation ($input: ControlBlockExplorerInput!) {
  controlBlockExplorer(input: $input)
}`;

export type MutationControlBlockExplorerArgs = Gtypes.MutationControlBlockExplorerArgs;

export type CONTROL_BLOCK_EXPLORER_RESULT_TYPE = {
  controlBlockExplorer: boolean;
};

export const grantNetworkRoleMutation = `mutation ($input: GrantNetworkRoleInput!) {
  grantNetworkRole(input: $input)
}`;

export type MutationGrantNetworkRoleArgs = Gtypes.MutationGrantNetworkRoleArgs;

export type GRANT_NETWORK_ROLE_RESULT_TYPE = {
  grantNetworkRole: Boolean;
};

export const revokeNetworkRoleMutation = `mutation ($input: RevokeNetworkRoleInput!) {
  revokeNetworkRole(input: $input)
}`;

export type MutationRevokeNetworkRoleArgs = Gtypes.MutationRevokeNetworkRoleArgs;

export type REVOKE_NETWORK_ROLE_RESULT_TYPE = {
  revokeNetworkRole: boolean;
};

export type UserPreference = Gtypes.UserPreference;

const restrictionQuery = `{
  protocol
  inbound {
    cidr
    desc
  }
}`;

export const listEndpointRestrictionsQuery = `query ($accountUuid: String!, $networkUuid: String!, $clusterUuid: String!) {
  listEndpointRestrictions(accountUuid: $accountUuid, networkUuid: $networkUuid, clusterUuid: $clusterUuid) ${restrictionQuery}
}`;

export type QueryListEndpointRestrictionsArgs = Gtypes.QueryListEndpointRestrictionsArgs;

export type LIST_ENDPOINT_RESTRICTIONS_RESULT_TYPE = {
  listEndpointRestrictions: Gtypes.RestrictionList[];
};

export const listExplorerRestrictionsQuery = `query ($accountUuid: String! $networkUuid: String!, $clusterUuid: String!) {
  listExplorerRestrictions(accountUuid: $accountUuid, networkUuid: $networkUuid, clusterUuid: $clusterUuid) ${restrictionQuery}
}`;

export type QueryListExplorerRestrictionsArgs = Gtypes.QueryListExplorerRestrictionsArgs;

export type LIST_EXPLORER_RESTRICTIONS_RESULT_TYPE = {
  listExplorerRestrictions: Gtypes.RestrictionList[];
};

export const listNodeRestrictionsQuery = `query ($input: RestrictionListNodeInput!) {
  listNodeRestrictions(input: $input) ${restrictionQuery}
}`;

export type QueryListNodeRestrictionsArgs = Gtypes.QueryListNodeRestrictionsArgs;

export type LIST_NODE_RESTRICTIONS_RESULT_TYPE = {
  listNodeRestrictions: Gtypes.RestrictionList[];
};

export const setEndpointRestrictionMutation = `mutation ($input: RestrictionSetEndpointInput!) {
  setEndpointRestriction(input: $input) ${restrictionQuery}
}`;

export type MutationSetEndpointRestrictionArgs = Gtypes.MutationSetEndpointRestrictionArgs;

export type SET_ENDPOINT_RESTRICTION_RESULT_TYPE = {
  setEndpointRestriction: Gtypes.RestrictionList[];
};

export const setExplorerRestrictionMutation = `mutation ($input: RestrictionSetExplorerInput!) {
  setExplorerRestriction(input: $input) ${restrictionQuery}
}`;

export type MutationSetExplorerRestrictionArgs = Gtypes.MutationSetExplorerRestrictionArgs;

export type SET_EXPLORER_RESTRICTION_RESULT_TYPE = {
  setExplorerRestriction: Gtypes.RestrictionList[];
};

export const setNodeRestrictionMutation = `mutation ($input: RestrictionSetNodeInput!) {
  setNodeRestriction(input: $input) ${restrictionQuery}
}`;

export type MutationSetNodeRestrictionArgs = Gtypes.MutationSetNodeRestrictionArgs;

export type SET_NODE_RESTRICTION_RESULT_TYPE = {
  setNodeRestriction: Gtypes.RestrictionList[];
};

export type RestrictionList = Gtypes.RestrictionList;

const changeNodeQuery = `{
  status
  execAt
  data
}`;

export const expandNodeVolumeMutation = `mutation ($input: ExpandNodeVolumeInput!) {
  expandNodeVolume(input: $input) ${changeNodeQuery}
}`;

export type MutationExpandNodeVolumeArgs = Gtypes.MutationExpandNodeVolumeArgs;

export type EXPAND_NODE_VOLUME_RESULT_TYPE = {
  expandNodeVolume: Gtypes.ChangeNodeResult;
};

export type ChangeNodeResult = Gtypes.ChangeNodeResult;

export const expandBlockExplorerVolumeMutation = `mutation ($input: ExpandBlockExplorerVolumeInput!) {
  expandBlockExplorerVolume(input: $input) ${changeNodeQuery}
}`;

export type MutationExpandBlockExplorerVolumeArgs = Gtypes.MutationExpandBlockExplorerVolumeArgs;

export type EXPAND_BLOCK_EXPLORER_VOLUME_RESULT_TYPE = {
  expandBlockExplorerVolume: Gtypes.ChangeNodeResult;
};

const dataMetricsQuery = `
  fetchAt
  metrics {
    cpu {
      value
      timestamp
    }
    mem {
      value
      timestamp
    }
    sys {
      value
      timestamp
    }
    disk {
      value
      timestamp
    }
    swap {
      value
      timestamp
    }
  }
`;

const nodeMetricsQuery = `{
  nodeUuid
  ${dataMetricsQuery}
}`;

export const getNodeMetricsQuery = `query ($input: NodeMetricsInput!) {
  getNodeMetrics(input: $input) ${nodeMetricsQuery}
}`;

export type QueryGetNodeMetricsArgs = Gtypes.QueryGetNodeMetricsArgs;

export type GET_NODE_METRICS_RESULT_TYPE = {
  getNodeMetrics: Gtypes.NodeMetrics;
};

export type NodeMetrics = Gtypes.NodeMetrics;
export type NodeMetricsData = Gtypes.NodeMetricsData;
export type NodeMetricsHistory = Gtypes.NodeMetricsHistory;

const explorerMetricsQuery = `{
  clusterUuid
  ${dataMetricsQuery}
}`;

export const getExplorerMetricsQuery = `query ($input: ExplorerMetricsInput!) {
  getExplorerMetrics(input: $input) ${explorerMetricsQuery}
}`;

export type QueryGetExplorerMetricsArgs = Gtypes.QueryGetExplorerMetricsArgs;

export type GET_EXPLORER_METRICS_RESULT_TYPE = {
  getExplorerMetrics: Gtypes.ExplorerMetrics;
};

export type ExplorerMetrics = Gtypes.ExplorerMetrics;

const dataLogQuery = `
  fetchAt
  entries {
    message
    timestamp
  }
`;

export const getNodeLogQuery = `query ($input: NodeLogInput!) {
  getNodeLog(input: $input) {
    nodeUuid
    ${dataLogQuery}
  }
}`;

export type QueryGetNodeLogArgs = Gtypes.QueryGetNodeLogArgs;

export type GET_NODE_LOG_RESULT_TYPE = {
  getNodeLog: Gtypes.NodeLog;
};

export type NodeLog = Gtypes.NodeLog;
export type NodeLogEntry = Gtypes.NodeLogEntry;
export type NodeLogType = Gtypes.NodeLogType;
export type NodeLogLine = Gtypes.NodeLogLine;

export const getExplorerLogQuery = `query ($input: ExplorerLogInput!) {
  getExplorerLog(input: $input) {
    clusterUuid
    ${dataLogQuery}
  }
}`;

export type QueryGetExplorerLogArgs = Gtypes.QueryGetExplorerLogArgs;

export type GET_EXPLORER_LOG_RESULT_TYPE = {
  getExplorerLog: Gtypes.ExplorerLog;
};

export type ExplorerLog = Gtypes.ExplorerLog;
export type ClusterSizeType = Gtypes.ClusterSizeType;

export const sendAllEtherFromNodeMutation = `mutation ($input: SendAllEtherFromNodeInput!) {
  sendAllEtherFromNode(input: $input) ${networkQuery}
}`;

export type MutationSendAllEtherFromNodeArgs = Gtypes.MutationSendAllEtherFromNodeArgs;

export type SEND_ALL_ETHER_FROM_NODE_RESULT_TYPE = {
  sendAllEtherFromNode: Gtypes.Network;
};

export const estimateTransferGasQuery = `query ($input: SendAllEtherFromNodeInput!) {
  estimateTransferGas(input: $input)
}`;

export type QueryEstimateTransferGasArgs = Gtypes.QueryEstimateTransferGasArgs;

export type ESTIMATE_TRANSFER_GAS_RESULT_TYPE = {
  estimateTransferGas: string;
};

export const checkClusterRemovalStatusQuery = `query ($accountUuid: String!, $networkUuid: String!, $clusterUuid: String!) {
  checkClusterRemovalStatus(accountUuid: $accountUuid, networkUuid: $networkUuid, clusterUuid: $clusterUuid) {
    syncedBlock
    syncedBcSigner
    detectBcSigner
  }
}`;

export type QueryCheckClusterRemovalStatusArgs = Gtypes.QueryCheckClusterRemovalStatusArgs;

export type CLUSTER_REMOVAL_STATUS_RESULT_TYPE = {
  checkClusterRemovalStatus: Gtypes.ClusterRemovalStatus;
};

export const checkNodeRemovalStatusQuery = `query ($accountUuid: String!, $networkUuid: String!, $clusterUuid: String!, $nodeUuid: String!) {
  checkNodeRemovalStatus(accountUuid: $accountUuid, networkUuid: $networkUuid, clusterUuid: $clusterUuid, nodeUuid: $nodeUuid) {
    syncedBlock
    syncedBcSigner
    detectBcSigner
  }
}`;

export type QueryCheckNodeRemovalStatusArgs = Gtypes.QueryCheckNodeRemovalStatusArgs;

export type NODE_REMOVAL_STATUS_RESULT_TYPE = {
  checkNodeRemovalStatus: Gtypes.ClusterRemovalStatus;
};

export type QueryGetTxpoolStatusArgs = Gtypes.QueryGetTxpoolStatusArgs;

export type GET_TXPOOL_STATUS_RESULT_TYPE = {
  getTxpoolStatus: string;
};

export const getTxpoolStatusQuery = `query ($networkUuid: String!, $clusterUuid: String!, $nodeUuid: String!) {
  getTxpoolStatus(networkUuid: $networkUuid, clusterUuid: $clusterUuid, nodeUuid: $nodeUuid)
}`;

export const listConsortiumsQuery = `query($accountUuid: String!, $networkUuid: String!) {
  listConsortiums(accountUuid: $accountUuid, networkUuid: $networkUuid) ${consortiumQuery}
}`;
export type QueryListConsortiumsArgs = Gtypes.QueryListConsortiumsArgs;
export type LIST_CONSORTIUMS_RESULT_TYPE = {
  listConsortiums: Array<Gtypes.Consortium>;
};

export const withdrawConsortiumMutation = `mutation($input: WithdrawConsortiumInput!) {
  withdrawConsortium(input: $input)
}`;
export type MutationWithdrawConsortiumArgs = Gtypes.MutationWithdrawConsortiumArgs;
export type WITHDRAW_CONSORTIUM_RESULT_TYPE = {
  withdrawalConsortium: Boolean;
};

export const updateConsortiumRoleMutation = `mutation($input: UpdateConsortiumRoleInput!) {
  updateConsortiumRole(input: $input) ${consortiumQuery}
}`;
export type MutationUpdateConsortiumRoleArgs = Gtypes.MutationUpdateConsortiumRoleArgs;
export type UPDATE_CONSORTIUM_ROLE_RESULT_TYPE = {
  updateConsortiumRole: Gtypes.Consortium;
};

export const updateNetworkOptionMutation = `mutation ($input: UpdateNetworkOptionInput!) {
  updateNetworkOption(input: $input) ${networkQuery}
}`;

export type MutationUpdateNetworkOptionArgs = Gtypes.MutationUpdateNetworkOptionArgs;

export type UPDATE_NETWORK_OPTION_RESULT_TYPE = {
  // deadlock_resolver: Boolean
  updateNetworkOption: Gtypes.Network;
};

export const listCustomDomainsQuery = `query ($input: CustomDomainsInput!) {
  listCustomDomains(input: $input) ${customDomainDetailQuery}
}`;

export type QueryListCustomDomainsArgs = Gtypes.QueryListCustomDomainsArgs;

export type LIST_CUSTOM_DOMAINS_RESULT_TYPE = {
  listCustomDomains: Gtypes.CustomDomainDetailInfo[];
};

export const addCustomDomainMutation = `mutation ($input: AddCustomDomainInput!) {
  addCustomDomain(input: $input) ${customDomainQuery}
}`;
export type MutationAddCustomDomainArgs = Gtypes.MutationAddCustomDomainArgs;

export type ADD_CUSTOM_DOMAIN_RESULT_TYPE = {
  addCustomDomain: Gtypes.CustomDomainInfo;
};

export const removeCustomDomainMutation = `mutation ($input: RemoveCustomDomainInput!) {
  removeCustomDomain(input: $input)
}`;
export type MutationRemoveCustomDomainArgs = Gtypes.MutationRemoveCustomDomainArgs;

export type REMOVE_CUSTOM_DOMAIN_RESULT_TYPE = {
  removeCustomDomain: boolean;
};

export const checkCustomDomainStatusMutation = `mutation ($input: CheckCustomDomainStatusInput!) {
  checkCustomDomainStatus(input: $input)
}`;
export type MutationCheckCustomDomainStatusArgs = Gtypes.MutationCheckCustomDomainStatusArgs;

export type CHECK_CUSTOM_DOMAIN_STATUS_RESULT_TYPE = {
  checkCustomDomainStatus: boolean;
};

export const addBlockExplorerMutation = `mutation ($input: CreateBlockExplorerInput!) {
  createBlockExplorer(input: $input) {
    status
    execAt
    data {
      serverInfo {
        az
        ip
        status
        instanceId
        instanceImage
        instanceType
        explorerType
        endpointUrl
        systemSize
        systemUsed
        volumeSize
        volumeUsed
      }
    }
  }
}`;

export type MutationToCreateBlockExplorerArgs = Gtypes.MutationCreateBlockExplorerArgs;

export type ADD_BLOCK_EXPLORER_RESULT_TYPE = {
  createBlockExplorer: Gtypes.CreateBlockExplorerResult;
};

export const deleteBlockExplorer = `mutation ($input: DeleteBlockExplorerInput!) {
  deleteBlockExplorer(input: $input)
}`;

export type MutationDeleteBlockExplorerArgs = Gtypes.MutationDeleteBlockExplorerArgs;

export type REMOVE_BLOCK_EXPLORER_RESULT_TYPE = {
  deleteBlockExplorer: Boolean;
};

export const updateNodeVersion = `mutation ($input: UpdateNodeVersionInput!) {
  updateNodeVersion(input: $input)
}`;

export type UPDATE_NODE_VERSION_RESULT_TYPE = {
  updateNodeVersion: Boolean;
};

export const updateBlockExplorer = `mutation ($input: UpdateBlockExplorerInput!) {
  updateBlockExplorer(input: $input)
}`;

export type UPDATE_BLOCK_EXP_RESULT_TYPE = {
  updateBlockExplorer: Boolean;
};

export const updateNodeExplorerVersion = `mutation ($input: UpdateNodeExplorerVersionInput!) {
  updateNodeExplorerVersion(input: $input)
}`;

export type UPDATE_NODE_EXP_RESULT_TYPE = {
  updateNodeExplorerVersion: Boolean;
};

export const createHardForkProposal = `mutation ($input: CreateHardForkProposalInput!) {
  createHardForkProposal(input: $input)
}`;

export type SCHEDULE_HARD_FORK_RESULT_TYPE = {
  createHardForkProposal: Boolean;
};

export const voteHardForkProposal = `mutation ($input: VoteHardForkProposalInput!) {
  voteHardForkProposal(input: $input) ${hardForkProposalQuery}
}`;

export type VOTE_HARD_FORK_SCHEDULE_RESULT_TYPE = {
  voteHardForkProposal: Gtypes.HardForkProposal;
};

export const listHardForkProposals = `query ($accountUuid: String!, $networkUuid: String!, $page: InputPageInfo) {
  listHardForkProposals(accountUuid: $accountUuid, networkUuid: $networkUuid, page: $page) {
    proposals ${hardForkProposalQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
  }
}`;

export type LIST_HARD_FORK_PROPOSAL_RESULT_TYPE = {
  listHardForkProposals: Gtypes.ListHardForkProposals;
};

export const applyHardForkToNode = `mutation ($input: ApplyHardForkToNodeInput!) {
  applyHardForkToNode(input: $input)
}`;

export type APPLY_HARD_FORK_TO_NODE_RESULT_TYPE = {
  applyHardForkToNode: Boolean;
};

export const applyHardForkToBlockExplorer = `mutation ($input: ApplyHardForkToBlockExplorerInput!) {
  applyHardForkToBlockExplorer(input: $input)
}`;

export type APPLY_HARD_FORK_TO_EXPLORER_RESULT_TYPE = {
  applyHardForkToBlockExplorer: Boolean;
};

export const retryApplyHardForkToNetwork = `mutation ($input: RetryApplyHardForkToNetworkInput!) {
  retryApplyHardForkToNetwork(input: $input)
}`;

export type RETRY_APPLY_HARD_FORK_TO_NETWORK_RESULT_TYPE = {
  retryApplyHardForkToNetwork: Boolean;
};

export const updateBlockGasLimit = `mutation ($input: UpdateBlockGasLimitInput!) {
  updateBlockGasLimit(input: $input)
}`;

export type UPDATE_GAS_LIMIT_RESULT_TYPE = {
  updateBlockGasLimit: Boolean;
};

export const updateNodeInstanceType = `mutation ($input: UpdateNodeInstanceTypeInput!) {
  updateNodeInstanceType(input: $input){
    status
    execAt
    data
  }
}`;

export type UPDATE_NODE_INSTANCE_RESULT_TYPE = {
  updateNodeInstanceType: ChangeNodeResult;
};

export const updateBlockExplorerInstanceType = `mutation ($input: UpdateBlockExplorerInstanceTypeInput!) {
  updateBlockExplorerInstanceType(input: $input){
    status
    execAt
    data
  }
}`;

export type UPDATE_BLOCK_EXPLORER_INSTANCE_RESULT_TYPE = {
  updateBlockExplorerInstanceType: ChangeNodeResult;
};

// get genesis json
export const getGenesisJsonQuery = `query ($networkUuid: String!, $accountUuid: String!, $clusterUuid: String!, $nodeUuid: String!) {
  getGenesisJson(networkUuid: $networkUuid, accountUuid: $accountUuid, clusterUuid: $clusterUuid, nodeUuid: $nodeUuid)
}`;
export type GET_GENESIS_JSON_RESULT_TYPE = {
  getGenesisJson: string;
};

// get genesis json in explorer
export const getGenesisJsonInExplorerQuery = `query ($networkUuid: String!, $accountUuid: String!, $clusterUuid: String!) {
  getGenesisJsonInExplorer(networkUuid: $networkUuid, accountUuid: $accountUuid, clusterUuid: $clusterUuid)
}`;
export type GET_GENESIS_JSON_IN_EXPLORER_RESULT_TYPE = {
  getGenesisJsonInExplorer: string;
};

// update external clef
export type UPDATE_EXTERNAL_CLEF_RESULT_TYPE = {
  updateExternalClef: Boolean;
};
export const updateExternalClef = `mutation ($input: UpdateExternalClefInput!) {
  updateExternalClef(input: $input)
}`;

// upgrade latest block explorer
export type UPDATE_BLOCK_EXPLORER_VERSION = {
  updateBlockExplorerVersion: Boolean;
};
export const updateBlockExplorerVersion = `mutation ($input: UpdateBlockExplorerVersionInput!) {
  updateBlockExplorerVersion(input: $input)
}`;

// set loadbalancer listener
export type SET_LOAD_BALANCER_LISTENER = {
  setEndpointListener: Boolean;
};
export const setEndpointListener = `mutation ($input: SetEndpointListenerInput!) {
  setEndpointListener(input: $input)
}`;
