import React, { useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontBold } from '~/styles/themes/common-styles/font';
import { whiteColor } from '~/styles/themes/common-styles/color';
import { LGPelorousDenim } from '~/styles/themes/common-styles/misc';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';

import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

export type AlertType = 'info' | 'warning' | 'error';
interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  title: string;
  content: string;
  type?: AlertType;
  onClose: () => void;
  onSubmit: () => void;
}

const CustomConfirmDialog = (props: IProps) => {
  const { classes, open, title, content, type = 'warning', onClose, onSubmit } = props;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  return (
    <CustomDialog open={open} onClose={onClose}>
      <CustomDialogTitle>
        <div id="member-custom-cf-dialog-title">{title}</div>
      </CustomDialogTitle>
      <CustomDialogContent>
        <div id="member-custom-cf-dialog-attention" className={classes.warningText}>
          {content}
        </div>
      </CustomDialogContent>
      <CustomDialogActions>
        <Button
          id="member-custom-cf-dialog-cancel"
          className={classes.leftBtn}
          variant="contained"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          id="member-custom-cf-dialog-submit"
          className={classes.submitBtn}
          onClick={onSubmit}
        >
          {t('submit')}
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  );
};

const styles = createStyles({
  root: {},
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  submitBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    width: 160,
    height: 36,
    textTransform: 'none',
    backgroundColor: 'rgb(224,224,224) !important',
    backgroundImage: LGPelorousDenim,
    '&:hover': {
      backgroundImage: LGPelorousDenim,
    },
    '&:disabled': {
      backgroundImage: 'none',
    },
  },
});

export default withStyles(styles)(CustomConfirmDialog);
