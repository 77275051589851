import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as semver from 'semver';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';
import { Account } from '~/types/account-types';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import { INetworkProvider } from '~/types/network-types';
import {
  instancesSizeSelection,
  instancesSizeEnterpriseSelection,
  nodeGethSelection,
  clusterTypeSelection,
  clusterTypeEnterpriseSelection,
  blockExplorerSizeOptions,
  blockscoutBackendSelection,
} from '~/types/network-selection';

// Component
import CustomDialog from './custom-dialog';
import CustomSelect from './custom-select';
import CustomInput from './custom-input';
import CustomInputNum from './custom-input-num';
import CustomInputNumCommaFormat from './custom-input-num-comma-format';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import SubmitButton from './submit-button';

import ConfirmLicenseDialog from './confirm-license-dialog';
import RegisterAddressDialog from './register-address-dialog';

// React i18next
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

// defines
import {
  DEFAULT_PROVIDER_NAME,
  DEFAULT_CLUSTER_TYPE,
  DEFAULT_MIN_NODES_POA,
  DEFAULT_VERSION_GETH,
  DEFAULT_GAS_PRICE_MAX,
  DEFAULT_GAS_PRICE,
  DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
  DEFAULT_VERSION_BLOCKSCOUT_FRONTEND,
  DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
} from '~/constants/consts';
import {
  VALIDATE_CLUSTER_TYPE_NODES,
  MAX_CLUSTER_NAME_LENGTH,
  CREATE_CLUSTER_MIN_NUMBER_OF_NODE,
  CREATE_CLUSTER_MAX_NUMBER_OF_NODE,
} from '~/constants/validation';
import { BlockExplorerSizeType, NodeSizeType } from '~/gapi/gtypes';

interface IProps {
  open: boolean;
  onClose: () => void;
  networkUuid: string;
  accountUuid: string;

  network: any;
}

interface IState {
  openConfirmLicenseDialog: boolean;
  openRegisterAddressDialog: boolean;
  estimateDate: string;
  estimateFee: PaymentActions.EstimateSummary;
  requireCard: boolean;
  formik?: FormikActions<FormValues>;
}

interface IStateProps {
  providers: INetworkProvider[];
  accountSeleted?: Account;
}

interface IDispProps {
  createCluster: (
    args: NetworkActions.MutationCreateClusterArgs,
  ) => Promise<NetworkActions.CREATE_CLUSTER_RESULT_TYPE>;
  insufficientLicenses: (
    args: PaymentActions.QueryListInsufficientClusterLicensesArgs,
  ) => Promise<PaymentActions.LIST_INSUFFICIENT_CLUSTER_LICENSES_RESULT_TYPE>;
  estimateLicenseFee: (
    args: PaymentActions.QueryEstimateLicenseFeeArgs,
  ) => Promise<PaymentActions.ESTIMATE_LICENSE_FEE_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentActions.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentActions.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
  listBillings: (
    args: PaymentActions.QueryListBillingsArgs,
  ) => Promise<PaymentActions.LIST_BILLINGS_RESULT_TYPE>;
  listCoupons: (
    args: PaymentActions.QueryListCouponsArgs,
  ) => Promise<PaymentActions.LIST_COUPONS_RESULT_TYPE>;
}

class CreateClusterDialog extends React.Component<
  IProps & IStateProps & IDispProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      openConfirmLicenseDialog: false,
      openRegisterAddressDialog: false,
      requireCard: false,
      estimateDate: '',
      estimateFee: {
        estimate: [
          {
            totalPrice: 0,
            taxFee: 0,
            taxRate: 0,
            licenses: [],
            nextMonth: {
              totalPrice: 0,
              taxFee: 0,
              taxRate: 0,
              licenses: [],
            },
          },
        ],
        coupon: {
          usable: 0,
        },
      },
    };
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  public render() {
    const { classes, open, onClose, network, accountSeleted, t } = this.props;
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;
    const requireAddr =
      !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
        ? true
        : false;

    const validateSchema = Yup.object().shape({
      clusterName: Yup.string()
        .required(t('required_field'))
        .max(
          MAX_CLUSTER_NAME_LENGTH,
          t('too_many_character_of_clustername_error_message', { value: MAX_CLUSTER_NAME_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return (name || '').trim() !== '' ? true : false;
          },
        ),
      clusterType: Yup.string()
        .required(t('required_field'))
        .oneOf(
          select.map((c) => c.value),
          t('invalid_cluster_type'),
        ),
      provider: Yup.string().required(t('required_field')),
      region: Yup.string().required(t('required_field')),
      instanceType: Yup.string()
        .required(t('required_field'))
        .test(
          'check-with-cluster-type',
          t('cluster_type_not_allowed_this_instance'),
          function (instance: string) {
            const validator =
              VALIDATE_CLUSTER_TYPE_NODES[this.parent.clusterType || DEFAULT_CLUSTER_TYPE];
            return validator.allowed.includes(instance);
          },
        ),
      numberOfNode: Yup.number()
        .required(t('required_field'))
        .min(
          CREATE_CLUSTER_MIN_NUMBER_OF_NODE,
          t('validate_minimum', { val: CREATE_CLUSTER_MIN_NUMBER_OF_NODE }),
        )
        .max(
          CREATE_CLUSTER_MAX_NUMBER_OF_NODE,
          t('validate_maximum', { val: CREATE_CLUSTER_MAX_NUMBER_OF_NODE }),
        ),
      nodeVersion: Yup.string().required(t('required_field')),
      gasPrice: Yup.number()
        .notRequired()
        .min(0, t('validate_minimum', { val: 0 }))
        .max(DEFAULT_GAS_PRICE_MAX, t('validate_maximum', { val: DEFAULT_GAS_PRICE_MAX })),
    });

    const {
      openConfirmLicenseDialog,
      openRegisterAddressDialog,
      estimateDate,
      estimateFee,
      requireCard,
    } = this.state;

    let convGasPrice = Number.NaN;
    if (network.blockchainInfo) {
      convGasPrice =
        network.blockchainInfo.defaultGasPrice !== ''
          ? parseInt(network.blockchainInfo.defaultGasPrice)
          : Number.NaN;
    }

    const initialValues: FormValues = {
      clusterName: '',
      clusterType: DEFAULT_CLUSTER_TYPE,
      provider: DEFAULT_PROVIDER_NAME,
      region: '',
      instanceType: 'small_v3',
      blockExplorerInstanceType: 'large_v3',
      numberOfNode: 1,
      nodeVersion: DEFAULT_VERSION_GETH,
      gasPrice: !Number.isNaN(convGasPrice) ? convGasPrice : DEFAULT_GAS_PRICE,
      includedBlockExplorer: false,
      blockscoutBackendVersion: DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
    };

    return (
      <>
        <CustomDialog open={open} onClose={onClose} scroll="body">
          <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={this.onSubmit}
            render={({ isValid, isSubmitting, values }) => (
              <Form>
                <CustomDialogTitle>
                  <div id="member-cluster-add-title">{t('create_cluster')}</div>
                </CustomDialogTitle>
                <CustomDialogContent classes={{ root: classes.dialogContentRoot }}>
                  <div>
                    <Field name="clusterName" render={this.clusterNameField} />
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="clusterType" render={this.clusterTypeField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <div className={classes.clusterTypeNote}>
                          {this.displayclusterTypeNote(values)}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="provider" render={this.providerField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <Field name="region" render={this.regionField} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="instanceType" render={this.instanceTypeField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <Field name="numberOfNode" render={this.numberOfNodeField} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="nodeVersion" render={this.nodeVersionField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <Field name="gasPrice" render={this.gasPriceField} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid
                        item
                        md={6}
                        className={classNames(classes.gridLeftItem, classes.formSectionCommonGrid)}
                      >
                        <Field
                          name="includedBlockExplorer"
                          render={this.includedBlockExplorerCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {values.includedBlockExplorer && (
                    <>
                      <div className={classes.formSection}>
                        <Grid container>
                          <Grid
                            item
                            md={6}
                            className={classNames(
                              classes.gridLeftItem,
                              classes.formSectionCommonGrid,
                            )}
                          >
                            <Field
                              name="blockExplorerInstanceType"
                              render={this.blockExplorerInstanceTypeField}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            className={classNames(
                              classes.gridRightItem,
                              classes.formSectionCommonGrid,
                            )}
                          >
                            <Field
                              name="blockscoutBackendVersion"
                              render={this.blockscoutBackendVersionField}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    id="member-cluster-add-cancel"
                    className={classes.leftBtn}
                    onClick={onClose}
                    variant="contained"
                  >
                    <Trans i18nKey={'cancel'} />
                  </Button>
                  <SubmitButton
                    id="member-cluster-add-submit"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('create')}
                    submittingLabel={t('creating')}
                  />
                </CustomDialogActions>
              </Form>
            )}
          />
        </CustomDialog>

        <RegisterAddressDialog
          open={openRegisterAddressDialog}
          onClose={this.onCloseRegisterAddressDialog}
        ></RegisterAddressDialog>
        <ConfirmLicenseDialog
          open={openConfirmLicenseDialog}
          onClose={this.onCloseConfirmLicenseDialog}
          estimateDate={estimateDate}
          estimateFee={estimateFee}
          requireAddr={requireAddr}
          requireCard={requireCard}
        ></ConfirmLicenseDialog>
      </>
    );
  }

  private clusterNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'cluster_name'} />
          </div>
          {!!form.errors.clusterName && form.touched.clusterName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.clusterName}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-cluster-add-name"
            placeholder={t('input_cluster_name')}
          />
        </div>
      </>
    );
  };

  private clusterTypeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t, accountSeleted } = this.props;
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('cluster_type')}</div>
          {!!form.errors.clusterType && form.touched.clusterType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.clusterType}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-cluster-add-cluster-type"
            valueSelected={field.value}
            placeholder={t('select_cluster_type')}
            items={select}
          />
        </div>
      </>
    );
  };

  private providerField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('provider')}</div>
          {!!form.errors.provider && form.touched.provider && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.provider}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-cluster-add-provider"
            valueSelected={field.value}
            placeholder={this.props.t('select_provider')}
            items={providers.map((item) => ({ value: item.value, label: item.label }))}
          />
        </div>
      </>
    );
  };

  private regionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers, t } = this.props;
    const provider = providers.find((item) => item.value === form.values.provider);
    let regions = [{ value: '', label: t('select_your_provider_first') }];

    if (provider) {
      regions = provider.region.map((item) => ({ value: item.value, label: item.label }));
    }
    if (!regions.find((r) => r.value === field.value)) {
      field.value = '';
      form.values.region = field.value;
    }

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('region')}</div>
          {!!form.errors.region && form.touched.region && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.region}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-cluster-add-region"
            valueSelected={field.value}
            placeholder={t(provider ? 'select_region' : 'select_your_provider_first')}
            items={regions}
          />
        </div>
      </>
    );
  };

  private instanceTypeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t, accountSeleted } = this.props;
    const selectC =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;
    const selectI =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? instancesSizeEnterpriseSelection
        : instancesSizeSelection;

    const cluster = selectC.map((c) => c.value).find((c) => c === form.values.clusterType);
    const validate = VALIDATE_CLUSTER_TYPE_NODES[cluster || DEFAULT_CLUSTER_TYPE];
    const filtered = selectI.filter((item) => validate.allowed.includes(item.value));

    if (!filtered.find((i) => i.value === field.value)) {
      field.value = filtered.length > 0 ? filtered[0].value : '';
      form.values.instanceType = field.value;
    }

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('instance_type')}</div>
          {!!form.errors.instanceType && form.touched.instanceType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.instanceType}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-cluster-add-instance"
            valueSelected={field.value}
            placeholder={t('select_instance_type')}
            items={filtered}
          />
        </div>
      </>
    );
  };

  private blockExplorerInstanceTypeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_explorer_instance_type')}</div>
          {!!form.errors.blockExplorerInstanceType && form.touched.blockExplorerInstanceType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockExplorerInstanceType)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-block-explorer-instance"
            valueSelected={field.value}
            placeholder={t('select_block_explorer_instance_type')}
            items={blockExplorerSizeOptions}
          />
        </div>
      </>
    );
  };

  private blockscoutBackendVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_explorer_version')}</div>
          {!!form.errors.blockscoutBackendVersion && form.touched.blockscoutBackendVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockscoutBackendVersion)}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-network-add-ver-node"
            valueSelected={field.value}
            placeholder={t('select_node_version')}
            items={blockscoutBackendSelection}
          />
        </div>
      </>
    );
  };

  private numberOfNodeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('number_of_nodes')}</div>
          {!!form.errors.numberOfNode && form.touched.numberOfNode && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.numberOfNode}
            </div>
          )}
        </div>
        <div>
          <CustomInputNum
            {...field}
            id="member-cluster-add-num-nodes"
            min={1}
            max={CREATE_CLUSTER_MAX_NUMBER_OF_NODE}
            placeholder={t('input_number')}
          />
        </div>
      </>
    );
  };

  private nodeVersionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('node_version')}</div>
          {!!form.errors.nodeVersion && form.touched.nodeVersion && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.nodeVersion}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-cluster-add-ver-node"
            valueSelected={field.value}
            placeholder={t('select_node_version')}
            items={nodeGethSelection}
          />
        </div>
      </>
    );
  };

  private gasPriceField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('gas_price')}</div>
          {!!form.errors.gasPrice && form.touched.gasPrice && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.gasPrice)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNumCommaFormat
            {...field}
            id="member-network-add-gas-price"
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return (
                floatValue === undefined || (floatValue >= 0 && floatValue <= DEFAULT_GAS_PRICE_MAX)
              );
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
        </div>
      </>
    );
  };

  private includedBlockExplorerCheckbox = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <div>
        <FormControlLabel
          {...field}
          control={<Checkbox color="default" />}
          label={
            <span className={classes.formLabel}>{this.props.t('is_create_block_explorer')}</span>
          }
        />
      </div>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, networkUuid, createCluster, accountUuid, accountSeleted, t } = this.props;
    console.log(values);

    // Check selected account existence
    if (!accountSeleted) {
      return;
    }

    // check registration of address
    if (!accountSeleted.isRegisteredPaymentAddr) {
      this.setState({ formik: formikActions });
      this.onOpenRegisterAddressDialog();
      return;
    }

    try {
      const result = await createCluster({
        input: {
          networkUuid,
          accountUuid,
          clusterName: values.clusterName,
          clusterType: values.clusterType as NetworkActions.ClusterSizeType,
          provider: values.provider,
          region: values.region,
          instance: values.instanceType,
          nodeNums: values.numberOfNode || 1,
          includedBlockExplorer: values.includedBlockExplorer,
          blockExplorerSizeType: values.blockExplorerInstanceType,
          optional: {
            nodeVersion: values.nodeVersion,
            gasPrice: values.gasPrice,
            blockscoutBackendVersion:
              values.blockscoutBackendVersion || DEFAULT_VERSION_BLOCKSCOUT_BACKEND,
            blockscoutFrontendVersion:
              values.blockscoutBackendVersion &&
              semver.gte(
                values.blockscoutBackendVersion,
                DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
              )
                ? DEFAULT_VERSION_BLOCKSCOUT_FRONTEND
                : undefined,
          },
        },
      });
      const { status, execAt } = result.createCluster;

      // handling charge|register card|creation
      if (status.includes('success')) {
        setSubmitting(false);
        onClose();
      } else if (status.includes('needCharge')) {
        // update state
        this.setState({
          requireCard: status.includes('needRegister') ? true : false,
          formik: formikActions,
        });

        // check licenses
        const { insufficientLicenses, estimateLicenseFee } = this.props;
        const needs = await insufficientLicenses({
          accountUuid: accountSeleted.accountUuid,
          nodeNum: values.numberOfNode || DEFAULT_MIN_NODES_POA,
          nodeType: values.instanceType as PaymentActions.NodeSizeType,
          clusterType: values.clusterType as NetworkActions.ClusterSizeType,
          blockExplorerType: values.blockExplorerInstanceType,
          includedBlockExplorer: values.includedBlockExplorer,
        });

        const params = {
          purchaseDate: execAt,
          licenses: needs.listInsufficientClusterLicenses.map((l) => ({
            licenseItemId: l.licenseItemId,
            qty: l.qty,
          })),
        };
        const fee = await estimateLicenseFee({ accountUuid, input: [params] });
        this.setState({ estimateDate: execAt });

        if (fee.estimateLicenseFee.estimate.length > 0) {
          this.setState({ estimateFee: fee.estimateLicenseFee });
        }
        this.onOpenConfirmLicenseDialog();
      } else {
        setSubmitting(false);
        onClose(); // unknown status
      }
    } catch (err) {
      setSubmitting(false);
    }
  };

  private onCloseRegisterAddressDialog = (status: boolean = false) => {
    const { formik } = this.state;
    this.setState({
      openRegisterAddressDialog: false,
      formik: void 0,
    });

    if (formik) {
      if (status) {
        // if registration successed, then start to buy licenses (auto submit)
        formik.submitForm();
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenRegisterAddressDialog = () => {
    this.setState({
      openRegisterAddressDialog: true,
    });
  };

  private onCloseConfirmLicenseDialog = (status: number = 0) => {
    const { formik, requireCard } = this.state;
    this.setState({
      openConfirmLicenseDialog: false,
      formik: void 0,
    });
    if (requireCard && status > 0) {
      this.setState({ requireCard: false });
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        const { accountSeleted, listActiveLicensesSummary, listBillings, listCoupons } = this.props;
        formik.submitForm();

        if (accountSeleted) {
          listBillings({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listCoupons({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }).catch(
            (_) => void 0,
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenConfirmLicenseDialog = () => {
    this.setState({
      openConfirmLicenseDialog: true,
    });
  };

  private displayclusterTypeNote = (values: FormValues) => {
    const { accountSeleted } = this.props;
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? clusterTypeEnterpriseSelection
        : clusterTypeSelection;
    const types = select.map((c) => c.value);

    if (!types.includes(values.clusterType)) {
      return '';
    }
    return this.props.t(`desc_cluster_${values.clusterType}`);
  };
}

const styles: StyleRules = {
  root: {},
  formSection: {
    marginTop: 15,
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  clusterTypeNote: {
    display: 'flex',
    height: '100%',
    paddingTop: '1.8em',
    ...defaultFont,
    fontSize: 12,
  },
  dialogContentRoot: {},
};

const mapStateToProps = (store: IStore, ownProps): IStateProps => ({
  providers: store.appState.providers || [],
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  createCluster: (args: NetworkActions.MutationCreateClusterArgs) =>
    dispatch(NetworkActions.createCluster(args)),
  insufficientLicenses: (args: PaymentActions.QueryListInsufficientClusterLicensesArgs) =>
    dispatch(PaymentActions.listInsufficientClusterLicenses(args)),
  estimateLicenseFee: (args: PaymentActions.QueryEstimateLicenseFeeArgs) =>
    dispatch(PaymentActions.estimateLicenseFee(args)),
  listActiveLicensesSummary: (args: PaymentActions.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentActions.listActiveLicensesSummary(args)),
  listBillings: (args: PaymentActions.QueryListBillingsArgs) =>
    dispatch(PaymentActions.listBillings(args)),
  listCoupons: (args: PaymentActions.QueryListCouponsArgs) =>
    dispatch(PaymentActions.listCoupons(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateClusterDialog)),
);

type FormValues = {
  clusterName: string;
  clusterType: string;
  provider: string;
  region: string;
  instanceType: NodeSizeType;
  blockExplorerInstanceType: BlockExplorerSizeType;
  numberOfNode: number;
  nodeVersion: string;
  gasPrice: number;
  includedBlockExplorer?: boolean;
  blockscoutBackendVersion?: string;
};
