export default {
  // general
  country: 'Country',
  state: 'State',
  city: 'City',
  postal_code: 'Postal Code',
  address1: 'Address 1',
  address2: 'Address 2',
  expired: 'Expired',
  invalid: 'Invalid',
  card_number_must_be_between_14_16_digit_number: 'Card number must be between 14-16 digit number',
  card_number_allows_only_numeric_characters: 'Card number allows only numeric characters',
  name_of_card_too_long: 'Name of card too long!',
  name_of_card_allows_alpha_numeric_space:
    'Name of card allows alpha-numeric, space and - _ . characters',
  desc_of_secvurity_allows_alpha_numeric_symbol:
    'Description of security allows alpha-numeric and some symbol (a-z、A-Z、0-9、. _-:/()#,@[]+=&;{}!$*) characters',
  license_instruction: 'You will need to purchase the missing licenses below',
  the_following_fees_will_be_charged_on_a_daily_basis_this_month:
    'In this case, the following amounts will be charged on a daily basis',
  network_will_remain_with_same_configuration_then_you_will_be_charged_monthly_fee_above:
    'The remaining {{ amount }} USD will be automatically carried over to the next Invoice.<br/>If you do not cancel by the end of the month, you will be billed on a monthly basis',
  required: 'Required',
  invalid_email: 'Invalid email',
  too_many_character_error_message: 'Please enter up to {{ value }} characters.',
  too_many_character_of_network_details_error_message: 'Please enter up to {{ value }} characters.',
  too_many_character_of_nodename_error_message: 'Please enter up to {{ value }} characters.',
  too_many_character_of_clustername_error_message: 'Please enter up to {{ value }} characters.',
  too_many_character_of_organization_name_error_message:
    'Please enter up to {{ value }} characters.',
  too_many_character_of_profile_name_error_message: 'Please enter up to {{ value }} characters.',
  for: 'for',
  days: 'days',
  per_month: 'Per month',
  license_statement: 'License Statement',
  contract_detail: 'Contract Detail',
  amount_billing: 'Amount Billing',
  daily_rate_total: 'Daily rate total',
  coupon_balance: 'Coupon balance',
  coupon_amount_used_this_time: 'Coupon amount used this time',
  blockchain_network: 'Blockchain Network',
  select: 'Select',
  nodes: 'Nodes',
  notifications: 'Notifications',
  access: 'Access',
  request: 'Requests',
  invitation: 'Invitations',
  settings: 'Settings',
  transfer_role: 'Transfer',
  charts: 'Charts',
  docs: 'Docs',
  billing: 'Billing',
  support: 'Support',
  find_a_network: 'Find a Network',
  new_network: 'New Network',
  profile_settings: 'Profile Setting',
  organization_settings: 'Organization Setting',
  logout: 'Sign out',
  admin: 'Admin',
  owner: 'Owner',
  member: 'Member',
  guest: 'Guest',
  operator: 'Operator',
  alert_createAt: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  user_createAt: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  user_lastLoginAt: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  approve: 'Approve',
  pending: 'Pending',
  verified: 'Verified',
  failure: 'Failure',
  accept: 'Accept',
  reject: 'Reject',
  request_last_received_date: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  request_last_sent_date: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  general: 'General',
  contact: 'Contact',
  members: 'Members',
  organization_setting: 'Organization Setting',
  save_change: 'Save Change',
  updating: 'Updating',
  processing: 'Processing',
  plan: 'Plan',
  upgrade_plan: 'Upgrade Plan',
  card_number: 'Card Number',
  change_credit_card: 'Change Credit Card',
  name: 'Name',
  input_email_address: 'Input Email Address',
  add_user: 'Add User',
  add_member: 'Add Member',
  member_name: 'Member Name',
  cancel: 'Cancel',
  submit: 'Submit',
  create: 'Create',
  creating: 'Creating',
  add_rule: 'Add Rule',
  email: 'Email',
  organization_role: 'Organization Role',
  primary_owner: 'Primary Owner',
  select_role: 'Select Role',
  authorize_node: 'Authorize Node',
  request_from: 'Request from',
  node: 'Node',
  proposal_type: 'Proposal Type',
  proposed_date_lbl: 'Proposed Date',
  node_id: 'Node Id',
  organization: 'Organization',
  cluster_name: 'Cluster Name',
  cluster_type: 'Cluster Type',
  signing_address: 'Signing Address',
  currency_name: 'Currency Name',
  currency_symbol: 'Currency Symbol',
  currency_name_input: 'Default: Ether',
  currency_symbol_input: 'Default: ETH',
  too_many_currency_character_error_message: 'Fill up to {{ value }} characters.',
  attention_currency_updation: 'Please rebuild the block explorer to observe currency changes',
  enode: 'enode',
  server: 'Server',
  provider: 'Provider',
  select_your_provider_first: 'Select your provider first',
  region: 'Region',
  select_your_region_first: 'Select your region first',
  availability_zone: 'Availability Zone',
  instance_type: 'Instance Type',
  block_explorer_instance_type: 'Block Explorer Instance Type',
  join: 'Join',
  create_organization: 'Create Organization',
  please_enter_your_organization_name: 'Please enter your organization name.',
  proposal_already_exist: 'Proposal already exists',
  note: 'Note',
  enter_your_company_name_or_team_name_as_organization_name:
    'Enter your company name or team name as organization name.',
  this_service_is_billed_per_organization: 'This service is billed per organization.',
  organization_name: 'Organization Name',
  input_organization_name: 'Input Organization Name',
  input_transfer_addr: 'Input Transfer Address',
  input_accountant_email: 'Input Accountant Email',
  input_system_admin_email: 'Input System Administrator Email',
  transfer_addr: 'Transfer Address',
  create_cluster: 'Create Cluster',
  input_cluster_name: 'Input Cluster Name',
  select_cluster_type: 'Select Cluster Type',
  select_provider: 'Select Provider',
  select_region: 'Select Region',
  select_instance_type: 'Select Instance Type',
  select_block_explorer_instance_type: 'Select Block Explorer Instance Type',
  number_of_nodes: 'Number of Nodes',
  number_of_nodes_must_greater_or_equal_8:
    'Number of Nodes must greater or equal 8 when block interval is less than 5sec',
  input_number: 'Input Number',
  input_gas_limit: 'Input Gas Limit',
  input_block_interval: 'Input Block Interval',
  create_blockchain_network: 'Create Blockchain Network (Consortium)',
  advanced_menu: 'Advanced Menu',
  network_name: 'Network Name',
  is_create_block_explorer: 'Create Block Explorer',
  input_network_name: 'Input Network Name',
  input_network_desc: 'Description within 1000 characters',
  initial_address: 'Initial Address (allocAddress)',
  input_initial_address: 'Input initial address',
  initial_balance: 'Initial Balance (allocBalance)',
  input_initial_balance: 'Input initial balance',
  block_interval: 'Block Interval',
  block_gas_limit: 'Block Gas Limit',
  gas_price: 'Gas Price',
  default_gas_price: 'Default Gas Price',
  os_version: 'OS Version',
  select_os_version: 'Select OS Version',
  node_version: 'Node Version',
  select_node_version: 'Select Node Version',
  garbage_collection_mode: 'Garbage collection mode',
  select_garbage_collection_mode: 'Select garbage collection mode',
  consensus_algorithm: 'Consensus Algorithm',
  select_consensus_algorithm: 'Select Consensus Algorithm',
  create_node: 'Create Node',
  create_block_explorer: 'Create Block Explorer',
  node_name: 'Node Name',
  node_size: 'Data Volume Size',
  input_node_name: 'Input Node Name',
  input_node_size: 'Input Data Volume Size',
  provider_name: 'Provider Name',
  region_name: 'Region Name',
  delete_member: 'Delete Member',
  user_name: 'User Name',
  are_you_sure_you_want_to_delete_this_member: 'Are you sure you want to delete this member?',
  Are_you_sure_you_want_to_transfer_the_primary_owner:
    'Are you sure you want to transfer the Primary Owner?',
  confirm: 'Confirm',
  delete: 'Delete',
  deleting: 'Deleting',
  delete_cluster: 'Delete Cluster',
  endpoint: 'Endpoint',
  cluster_id: 'Cluster Id',
  are_you_sure_you_want_to_delete_this_cluster: 'Are you sure you want to delete this cluster?',
  destroy_network: 'Destroy Network',
  network_id: 'Network Id',
  chain_id: 'Chain Id',
  number_of_cluster: 'Number of Cluster',
  are_you_sure_you_want_to_delete_this_network: 'Are you sure you want to delete this network?',
  destroy_network_dialog_input_placeholder: 'Please enter your Network Name.',
  network_has_more_than_one_signer:
    'Can not delete the network because there is a Validator node in the other organization account.',
  removing_nodes_and_clusters: 'Removing nodes and clusters ...',
  delete_node: 'Delete Node',
  are_you_sure_you_want_to_delete_this_node: 'Are you sure you want to delete this node?',
  delete_user: 'Delete User',
  network_role: 'Network Role',
  edit_member: 'Edit Member',
  update: 'Update',
  edit_cluster: 'Edit Cluster',
  edit_network: 'Edit Network',
  blockchain_network_id: 'Blockchain Network ID',
  edit_node: 'Edit Node',
  display_name: 'Display Name',
  input_display_name: 'Input Display Name',
  language: 'Language',
  select_language: 'Select Language',
  account: 'Account',
  organizations_you_belong_to: 'Organizations you belong to',
  network: 'Network',
  networks_you_have_control: 'Networks you have control',
  security: 'Security',
  log_out_of_all_sessions_except_this_current_browser:
    'Log out of all sessions except this current browser',
  log_out_other_sessions: 'Log out other sessions',
  password: 'Password',
  you_are_currently_logged_in_with_your_Google_account:
    'You’re currently logged in with your Google account',
  change_account_password: 'Change account password',
  change_password: 'Change password',
  edit_user: 'Edit User',
  user_id: 'User Id',
  input_user_id: 'Input User Id',
  input_user_name: 'Input User Name',
  grant_access: 'Grant Access',
  grant: 'Grant',
  granting: 'Granting',
  there_is_a_notification: 'You have new notifications',
  there_is_a_node_of_waiting_for_approval: 'You have new notifications',
  see_all: 'See all',
  add_new_organization: 'Add New Organization',
  organizations: 'Organizations',
  organization_count: '{{count}} Organization',
  organization_count_plural: '{{count}} Organizations',
  you: 'You',
  invite_network: 'Invite Network',
  send: 'Send',
  sending: 'Sending',
  organization_id: 'Organization ID',
  email_address: 'Email Address',
  find_a_user: 'Find a user',
  role: 'Role',
  create_date: 'Create Date',
  last_login: 'Last Login',
  actions: 'Actions',
  find_notification: 'Find Notification',
  current_display: 'Current Display',
  type: 'Type',
  size: 'Size',
  quantity: 'Quantity',
  month_current: 'This month',
  month_next: 'Next month',
  title: 'Title',
  date: 'Date',
  amount: 'Amount',
  invoice_no: 'Invoice No',
  important: 'Important',
  information: 'Information',
  warning: 'Warning',
  error: 'Error',
  help: 'Help',
  cluster_information: 'Cluster Information',
  signer_node_count: 'Validator Node Count',
  transaction_node_count: 'Relay Node Count',
  recent_event: 'Recent Event',
  description: 'Description',
  states: 'States',
  blockchain_id: 'Blockchain ID',
  node_type: 'Node type',
  overview: 'Overview',
  accepted: 'Accepted',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  received: 'Received',
  sent: 'Sent',
  action: 'Action',
  get_proposals: 'Get Proposals',
  getting: 'Getting',
  from_coin_base_address: 'From Coin Base Address',
  from_node_uuid: 'From Node Uuid',
  to_coin_base_address: 'To Coin Base Address',
  to_node_uuid: 'To Node Uuid',
  propose_state: 'Propose state',
  invite: 'Invite',
  average_block_time: 'Average block time',
  total_transactions: 'Total transactions',
  wallet_addresses: 'Wallet addresses',
  total_blocks: 'Total blocks',
  network_information: 'Network Information',
  network_uuid: 'Network Uuid',
  number_of_nodes_clusters: 'Num of Nodes / Cluster',
  others_organization_s_cluster: "Others organization's cluster",
  metrics: 'Metrics',
  block_explorer: 'Block Explorer',
  node_count: 'Node Count',
  geth_RPC_endpoint: 'Geth RPC Endpoint',
  block_explorer_URL: 'Block Explorer URL',
  approved: 'Approved',
  create_new_blockchain_network: 'Create New Blockchain Network',
  ip_address: 'IP address',
  public_DNS_name: 'Public DNS name',
  node_detail_page_proposer_description: 'To change a node type, send a proposal',
  send_proposal: 'Send Proposal',
  approval: 'Approval',
  page_not_found: 'Page Not Found',
  find_a_node: 'Find a node',
  cluster: 'Cluster',
  sender: 'Sender',
  receiver_you: 'Receiver (You)',
  request_types: 'Request Types',
  last_received_date: 'Last Received Date',
  signer: 'Signer',
  transaction: 'Transaction',
  sender_you: 'Sender (You)',
  receiver: 'Receiver',
  last_sent_date: 'Last Sent Date',
  appropval_status: 'Approval Status',
  proposals: 'Proposals',
  approve_all: 'Approve all',
  reject_all: 'Reject all',
  my_cluster: 'My Cluster',
  edit: 'Edit',
  proposed_date: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  status: 'Status',
  approved_nodes: 'Approved Nodes',
  rejected_nodes: 'Rejected Nodes',
  not_respond_nodes: 'Not respond Nodes',
  num_of_signers: 'Num of Signers',
  link: 'link',
  error_alloc_address: `40 digit hexadecimal number(a-f,0-9) required`,
  error_hexadecimal_number_required: 'Hexadecimal number(a-f,A-F,0-9) required',
  error_zero_balance_not_allowed: 'Allocated balance should be greater than zero',
  required_field: 'This field is required',
  integer_field: 'Must be an integer',
  input_organization_id: 'Input organization ID',
  invitation_update_at: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  proposing: 'Proposing...',
  proposed: 'Proposed',
  sent_approval: 'Sent Approval',
  sent_rejection: 'Sent Rejection',
  must_be_greater_than_current_size: 'Must be greater than current size',
  must_be_less_than_or_equal_to_16tb: 'Must be less than or equal to 16,000 (16TB)',
  possible_zero_signer_error: `Possible 0 Validator node, can't send proposal`,
  force_delete_message: `If you're really sure to destroy the network, type the network name below.`,
  applying: 'Applying...',
  payment_way: 'Payment',
  license: 'License',
  bill_state: 'Statement',
  register: 'Register',
  purchase: 'Purchase',
  purchasing: 'Purchasing',
  reboot: 'Reboot',
  forceReboot: 'Force reboot',
  rebuild: 'Rebuild',
  stop: 'Stop',
  start: 'Start',
  port_range: 'Port range',
  protocol: 'Protocol',
  source: 'Source',
  deleted: 'Deleted',
  register_payment_method: 'Register Payment Method',
  the_following_cards_can_be_used: 'The following cards can be used.',
  register_your_card: 'Register',
  name_on_card: 'Name on card',
  expiration_date: 'Expiration date',
  cvc_number: 'CVC Number',
  expand: 'Expand Data Volume Size',
  transfer_primary_owner: 'Transfer Primary Owner',
  edit_inbound_rules: 'Edit Inbound Rules',
  edit_rules: 'Edit Rules',
  can_not_get_inbound_rules: 'Can not get Inbound Rules because instance is not setup yet',
  no_active_licenses: 'No active licenses',
  usage_history: 'Usage History',
  billing_month: 'Billing month',
  payment_status: 'Payment status',
  billing_amount: 'Billing amount',
  billing_date: 'Billing date',
  billing_date_value: '{{date, MM/DD/YYYY}}',
  billing_id: 'Billing ID',
  open: 'Open',
  paid: 'Paid',
  unpaid: 'Unpaid',
  unpaid_exist: 'Unpaid bill exist',
  payment_failed: 'Payment Failed',
  license_item_id: 'License Item ID',
  license_name: 'License name',
  unit_price: 'Unit price',
  subtotal: 'Subtotal',
  total: 'Total',
  tax: 'Tax',
  amount_of_coupon_used: 'Amount of coupon used',
  debug: 'Debug',
  mark_all_read: 'Mark All Read',
  withdraw: 'Withdrawal',
  option: 'Option',
  deadlock_resolver: 'Deadlock Resolver',
  please_input_organization_name_exactly_for_confirmation:
    'Please input organization name exactly for confirmation.',
  are_you_sure_you_want_to_withdraw_even_if_you_have_available_licenses:
    'Are you sure you want to withdraw even if you have available licenses?',
  used: 'used',
  node_disk_size: 'Disk size', //unused
  node_disk_used: 'Disk used', //unused
  volume_size: 'Data Volume',
  data_volume_size: 'Data Volume Size',
  payment_address: 'Payment Address',
  resource: 'Resource',
  cpu_usage: 'CPU usage',
  ram_usage: 'RAM usage',
  swap_usage: 'Swap usage',
  peer: 'Peer',
  peer_count: 'Peer count',
  logs: 'Logs',
  log_file: 'Log File',
  log_messages: 'Log Messages',
  x_warn_disk: 'Lack of data volume space',
  can_not_get_log: 'Log not found. Instance is not setup or log not exists yet.',
  warn_create_network_nodes_value:
    'Set Number of Nodes value to less than 8 may cause Increases the probability that the blockchain will stop.\nAre you sure you want to run?',
  validate_minimum: 'Input {{ val }} or greater',
  validate_maximum: 'Input {{ val }} or less',
  validate_maximum_length: 'Input {{ val }} characters or less',
  validate_length_100: 'Input 100 characters or less',
  validate_account_not_matched: "This operation is only allowed for the owner's account",
  validate_account_has_no_addr: 'No transfer address is specified in the account',
  monitoring: 'Monitoring',
  time_range: 'Time range :',
  metrics_range_option_label_last_hour: 'Last {{ value }} hour',
  metrics_range_option_label_last_hour_plural: 'Last {{ value }} hours',
  metrics_range_option_label_last_day: 'Last {{ value }} day',
  metrics_range_option_label_last_day_plural: 'Last {{ value }} days',
  metrics_range_option_label_last_week: 'Last {{ value }} week',
  metrics_range_option_label_last_week_plural: 'Last {{ value }} weeks',
  metrics_period_option_label_minute: '{{ value }} minute',
  metrics_period_option_label_minute_plural: '{{ value }} minutes',
  metrics_period_option_label_hour: '{{ value }} hour',
  metrics_period_option_label_hour_plural: '{{ value }} hours',
  metrics_period_option_label_day: '{{ value }} day',
  metrics_period_option_label_day_plural: '{{ value }} days',
  period: 'Period :',
  disk_capacity: 'Disk capacity', //unuse
  disk_usage: 'Disk usage', //unuse
  disk_data: 'DataDisk', //unuse
  system_volume: 'System Volume',
  memory: 'Memory',
  cpu: 'CPU',
  cpu_usage_pc: 'CPU usage (%)',
  ram_usage_pc: 'RAM usage (%)',
  swap_usage_pc: 'Swap usage (%)',
  disk_usage_pc: 'Data disk (%)', //unuse
  volume_usage_pc: 'Data Volume usage(%)',
  delete_organization: 'Delete Organization',
  are_you_sure_you_want_to_delete_organization: 'Are you sure you want to delete organization?',
  confirm_delete_organization_guide:
    'To delete an organization account, enter the organization account name in the field below.',
  execution_failed_please_try_again_later: 'Execution failed. Please try again later.',
  all_services_will_not_be_available_after_withdrawal:
    'All services will not be available after withdrawal.',
  organization_setting_withdrawal_notice:
    'Notice: To delete the Organization Account, the user of the Organization Account must be deleted except for the Owner, and all networks under the Organization Account must be deleted and there are no outstanding accounts.',
  account_name_not_matched: 'Account name not matched',
  download_full_log: 'Download Full Log',
  lines: 'Lines',
  get_log: 'Get Log',
  expires: 'Expires',
  standard_plan: 'Standard Plan',
  active: 'Active',
  your_credit_cards: 'Your credit cards',
  coupons: 'Coupons',
  granted_date: 'Granted Date',
  coupon_name: 'Coupon Name',
  expire_date: 'Expire Date',
  invalid_cluster_type: 'Invalid cluster type',
  cluster_type_not_allowed_this_instance: 'Selected cluster type does not allow this instance',
  cluster_type_not_allowed_this_nums: 'Lack of nodes for selected cluster type',
  cluster_not_selected: 'Cluster not selected',
  desc_cluster_small: 'For productions with low access volume, such as practical verification',
  desc_cluster_medium: 'For productions with 10-100tps average of traffic',
  desc_cluster_large: 'For productions more than 100tps average of traffic',
  desc_cluster_mega: 'For productions more than 100tps average of traffic',
  desc_cluster_enterprise: 'For enterprise use',
  balance: 'Balance',
  transfer: 'Send {{ value }}',
  lack_of_balance_to_transfer: 'Lack of balance to send transaction',
  invalid_hex_address_format: 'Address must to be a 42-digit hexadecimal format starting with "0x"',
  confirm_send_ether:
    'Transfer {{ value }} stored in this node to specified address in account.\nAre you sure you want to do this?',
  an_error_occured: 'An error occured',
  language_not_exist: 'Language does not exist',
  account_not_exist: 'Account does not exist',
  account_not_selected: 'Account not selected',
  account_not_attached_to_aws: 'Account using enterprise plan but not attached to AWS account',
  user_not_exist: 'User profile does not exist',
  coupon_not_exist: 'Coupon does not exist',
  address_not_registered: 'Address not registered',
  aws_account_not_exist: 'AWS account not exist',
  aws_account_not_available: 'There is no available AWS account',
  aws_account_creation_failed: 'AWS account creation failed',
  invitation_already_exist: 'Invitation already exist',
  invitation_table_account_received_from: 'Account (Received From)',
  invitation_table_account_sent_to: 'Account (Sent To)',
  the_member_already_exists: 'The member already exists',
  invalid_country_code: 'Invalid country specified',
  invalid_postal_code: 'Please enter in half size numbers in the format of "000-0000" or "0000000"',
  can_not_register_account_address: 'Can not register account address',
  can_not_update_account_contact: 'Can not update account contact',
  update_account_address_success: 'Account address registered successfully',
  update_account_contact_success: 'Account contact registered successfully',
  billing_data_corrupted: 'Billing data corrupted',
  can_not_get_network: 'Can not find out network',
  can_not_get_node: 'Can not find out node',
  can_not_get_cluster: 'Can not find out node',
  can_not_get_proposal: 'Can not find out proposal',
  can_not_get_provider: 'Can not find out provider',

  the_network_is_still_in_the_process_of_construction:
    'The network is still in the process of construction',
  the_network_is_already_in_the_process_of_destruction:
    'The network is already in the process of destruction',
  the_cluster_is_already_in_the_process_of_destruction_or_construction:
    'The cluster is already in the process of destruction or construction',
  the_node_is_already_in_the_process_of_destruction_or_construction:
    'The node is already in the process of destruction or construction',
  the_block_explorer_is_already_in_the_process_of_destruction_or_construction:
    'The block explorer is already in the process of destruction or construction',
  the_node_has_an_inoperable_status: 'The node has an inoperable status',
  the_block_explorer_has_an_inoperable_status: 'The block explorer has an inoperable status',
  the_network_still_has_more_than_1_validator_belong_other_account:
    'The network still has more than 1 Validator nodes belong other account. You can not delete network.',
  the_network_still_has_more_than_1_validator_node:
    'The network still has more than 1 Validator nodes. Delete the Validator nodes down to the count of 1.',
  the_cluster_still_has_more_than_1_validator_node:
    'The cluster still has more than or equal 1 Validator nodes. Delete the Validator nodes first.',
  can_not_delete_the_last_cluster: 'Can not delete the last cluster',
  can_not_delete_the_last_node: 'Can not delete the last node',
  the_cluster_has_all_of_validator_nodes_of_network:
    'The cluster has all of Validator nodes of network',
  The_rule_has_already_been_registered_or_same_rule_is_specified:
    'The specified rule has already been registered or same rule is specified.',
  you_can_not_delete_a_validator_node:
    'You cannot delete a Validator node. You must downgrade the node to a Relay node first.',
  you_dont_have_a_permission_to_perform_the_account: `You don't have permission to perform the action`,
  you_dont_have_a_permission_to_perform_the_cluster: `You don't have permission to operate this cluster`,
  you_dont_have_a_permission_to_add_a_member_to_account: `You don't have permission to add a member to the account`,
  you_dont_have_a_permission_to_grant_an_access_role: `You don't have permission to grant an access role`,
  you_dont_have_a_permission_to_create_network_in_this_account: `You don't have a permission to create network in this account`,
  you_dont_have_a_permission_to_create_a_cluster_in_this_network: `You don't have a permission to create a cluster in this network`,
  you_dont_have_a_permission_to_create_a_node_in_this_cluster: `You don't have a permission to create a node in this cluster`,
  you_dont_have_a_permission_to_cancel_the_proposal: `You don't have permission to cancel the proposal`,
  you_dont_have_a_permission_to_send_ether: "You don't have a permission to send ether",
  you_dont_have_a_permission_to_delete_the_node: `You don't have a permission to delete the node`,
  fail_to_get_account_uuid: 'Fail to get accountUuid',
  fail_to_get_network_id: 'Fail to get network id',
  account_does_not_exist: 'Account does not exist',
  member_does_not_exist_in_account: 'Member does not exist in Account',
  member_already_exist: 'Member already exist',
  member_does_not_exist: 'Member does not exist',
  there_is_only_one_owner_can_not_revoke_owner: 'There is only one owner, can not revoke owner',
  there_is_owner_can_not_revoke_owner: 'There is Primary Owner, can not revoke Owner',
  members_who_are_not_owners_cannot_change_to_primary_owners:
    'members who are not owners cannot transfer to Primary Owners',
  user_still_have_more_than_1_network_owner_role:
    'User still have more than 1 network role as owner. Change all network role to non-owner value.',
  user_is_the_last_owner_of_a_network: 'User is the last owner of a network',
  can_not_revoke_owner_final: 'Can not revoke owner final',
  cant_not_revoke_last_owner: 'Can not revoke last owner',
  can_not_revoke_primary_owner: 'Can not revoke Primary Owner',
  geth_rpc_endpoint_notification: 'To use the Geth RPC Endpoint, the port must be open.',
  geth_rpc_endpoint_notification_for_member:
    'To use the Geth RPC Endpoint, the port must be open by authorized user.',
  add_inbound_rules: 'Add inbound rules',
  node_geth_did_not_up_after_reboot: 'Node geth did not up after reboot',
  block_explorer_http_did_not_up_after_reboot:
    'Block Explorer instance HTTP did not up after reboot',
  restartForce_command_sent: 'Force reboot command sent',
  stop_command_sent: 'Stop command sent',
  start_command_sent: 'Start command sent',
  reboot_command_sent: 'Reboot command sent',
  rebuild_command_sent: 'Rebuild command sent',
  maximum_modification_rate_per_volume_limit_reached:
    'Maximum modification rate per data volume limit reached. Please wait at least 6 hours.',
  the_amount_is_not_enough_for_the_minimum_settlement_amount:
    'The subtotal excluding tax must be more than 0.5USD.',
  the_node_to_be_proposed_to: 'The node to be proposed to',
  billing_address_dialog_title: 'Billing Address',
  billing_address_dialog_description: 'Enter your billing address for billing purposes.',
  billing_address_dialog_country_label: 'Country',
  billing_address_dialog_postcode_label: 'Postcode',
  billing_address_dialog_postcode_placeholder: '123-4567',
  billing_address_dialog_address_label: 'Address',
  billing_address_dialog_state_placeholder: 'State',
  billing_address_dialog_state_required_error_text: 'State field is required',
  billing_address_dialog_city_placeholder: 'City',
  billing_address_dialog_city_required_error_text: 'City field is required',
  billing_address_dialog_address_1_required_error_text: 'Address (1st line) field is required',
  billing_address_dialog_address_1_placeholder: 'Address (1st line)',
  billing_address_dialog_address_2_placeholder: 'Address (2nd line)',
  trailing_spaces_not_allowed_to_name: 'Trailing spaces not allowed to name',
  invalid_protocol_type: 'Invalid protocol type',
  invalid_ipv4_cidr_format: 'Invalid CIDR (IPv4) format. Format should be in x.x.x.x/32',
  error_my_ip_address: 'Can not find your IP address. Please enter you IP address manually.',
  amount_must_be_greater_than_zero: 'Amount must be greater than 0',
  invalid_date_format: 'Invalid date format',
  failed_to_update_payment_service_name: 'Failed to update payment service name',
  failed_to_update_payment_service_email: 'Failed to update payment service email',
  failed_to_update_payment_service_language: 'Failed to update payment service language',
  side_bar_payment_alert_title: 'Please register payment Information',
  side_bar_payment_alert_content:
    'You have not registered your payment information. To continue using the service, please register your payment information.',
  side_bar_payment_alert_button_label: 'Register your payment information',
  can_not_look_up_dns: 'Can not look up DNS record',
  can_not_revoke_yourself: 'Can not revoke yourself',
  can_not_grant_yourself: 'Can not grant yourself',
  network_create_fail_block_or_dns: 'Something wrong with block creation',
  specified_email_not_registered_in_system:
    'The specified email address is not registered in this system.',
  terms_of_service_header_title: 'Welcome to the G.U. Blockchain Cloud.',
  terms_of_service_checkbox_label: 'I agree to the Terms of Use',
  terms_of_service_disagree_button_label: 'Do not agree',
  terms_of_service_agree_button_label: 'Agree',
  billing_details_tab_title: 'Billing Details',
  billing_details_tab_country: 'Country',
  billing_details_tab_postcode: 'Postcode',
  billing_details_tab_address: 'Address',
  sign_in: 'Sign in',
  you_have_reached_the_creation_limit: 'You have reached the organization creation limit',
  node_information: 'Node information',
  server_information: 'Server information',
  block_explorer_information: 'Information',
  volume_size_less_than_the_minimum_limitation: 'Data volume size less than the minimum limitation',
  volume_size_exceeded_maximum_limitation: 'Data volume size exceeded maximum limitation',
  unmatch_storage_and_license_count: 'Unmatch data volume and license count',
  invalid_node_license_calculation: 'Invalid node license calculation',
  can_not_control_node: 'Can not control node',
  can_not_control_block_explorer: 'Can not control block explorer',
  can_not_expand_node_volume_size: 'Can not expand node data volume size',
  can_not_expand_block_explorer_volume_size: 'Can not expand block explorer data volume size',
  the_network_has_resource_which_in_process_of_destruction_or_construction:
    'Cannot operate because there is a node that is in the process of changing its state, such as starting or deleting.',
  edit_network_dialog_description_placeholder: 'Description within {{ value }} characters',
  billing_payment_tab_granted_date: '{{date, MMMM Do, YYYY}}',
  billing_payment_tab_expried_date: '{{date, MMMM Do, YYYY}}',
  unknown_payment_error: 'Unknown payment error occured',
  can_not_find_out_stripe_customer: 'Can not find out stripe customer',
  can_not_get_valid_card_expiration: 'Can not get valid card expiration',
  stripe_customer_is_deleted: 'Stripe customer is deleted',
  unknown_log_type_specified: 'Unknown log type specified',
  unable_to_send_ether_because_node_is_down: 'Unable to send ether because node is down',
  network_does_not_belong_to_account: "The network doesn't belong to the account",
  network_user_does_not_exist: 'Network user does not exist',
  this_function_is_only_available_in_enterprise_plans:
    'This function is only available in enterprise plans',
  run: 'Run',
  running: 'Running',
  owner_email: 'Owner Email',
  consortium: 'Consortium',
  edit_role: 'Edit Role',
  withdraw_from_network: 'Withdraw from the network',
  withdraw_consortium_warning_text: 'Are you sure you want to leave this network?',
  signer_node: 'Validator Node',
  transaction_node: 'Relay Node',
  consortium_admin: 'Consortium Admin',
  consortium_member: 'Consortium Member',
  // ----------
  //
  // stripe error codes (see https://stripe.com/docs/error-codes)
  //
  account_already_exists: 'Account already exists',
  account_country_invalid_address: 'Account country invalid address',
  account_invalid: 'Account invalid',
  account_number_invalid: 'Account number invalid',
  alipay_upgrade_required: 'Alipay upgrade required',
  amount_too_large: 'Amount too large',
  amount_too_small: 'Amount too small',
  api_key_expired: 'Api key expired',
  balance_insufficient: 'Balance insufficient',
  bank_account_exists: 'Bank account exists',
  bank_account_unusable: 'Bank account unusable',
  bank_account_unverified: 'Bank account unverified',
  bitcoin_upgrade_required: 'Bitcoin upgrade required',
  card_declined: 'Card declined',
  charge_already_captured: 'Charge already captured',
  charge_already_refunded: 'Charge already refunded',
  charge_disputed: 'Charge disputed',
  charge_exceeds_source_limit: 'Charge exceeds source limit',
  charge_expired_for_capture: 'Charge expired for capture',
  country_unsupported: 'Country unsupported',
  coupon_expired: 'Coupon expired',
  customer_max_subscriptions: 'Customer max subscriptions',
  email_invalid: 'Email invalid',
  expired_card: 'Expired card',
  idempotency_key_in_use: 'Idempotency key in use',
  incorrect_address: 'Incorrect address',
  incorrect_cvc: 'Incorrect cvc',
  incorrect_number: 'Incorrect number',
  incorrect_zip: 'Incorrect zip',
  instant_payouts_unsupported: 'Instant payouts unsupported',
  invalid_card_type: 'Invalid card type',
  invalid_charge_amount: 'Invalid charge amount',
  invalid_cvc: 'Invalid cvc',
  invalid_expiry_month: 'Invalid expiry month',
  invalid_expiry_year: 'Invalid expiry year',
  invalid_number: 'Invalid number',
  invalid_source_usage: 'Invalid source usage',
  invoice_no_customer_line_items: 'Invoice no customer line items',
  invoice_no_subscription_line_items: 'Invoice no subscription line items',
  invoice_not_editable: 'Invoice not editable',
  invoice_upcoming_none: 'Invoice upcoming none',
  livemode_mismatch: 'Livemode mismatch',
  missing: 'Missing',
  order_creation_failed: 'Order creation failed',
  order_required_settings: 'Order required settings',
  order_status_invalid: 'Order status invalid',
  order_upstream_timeout: 'Order upstream timeout',
  out_of_inventory: 'Out of inventory',
  parameter_invalid_empty: 'Parameter invalid empty',
  parameter_invalid_integer: 'Parameter invalid integer',
  parameter_invalid_string_blank: 'Parameter invalid string blank',
  parameter_invalid_string_empty: 'Parameter invalid string empty',
  parameter_missing: 'Parameter missing',
  parameter_unknown: 'Parameter unknown',
  payment_method_unactivated: 'Payment method unactivated',
  payouts_not_allowed: 'Payouts not allowed',
  platform_api_key_expired: 'Platform api key expired',
  postal_code_invalid: 'Postal code invalid',
  processing_error: 'Processing error',
  product_inactive: 'Product inactive',
  rate_limit: 'Rate limit',
  resource_already_exists: 'Resource already exists',
  resource_missing: 'Resource missing',
  routing_number_invalid: 'Routing number invalid',
  secret_key_required: 'Secret key required',
  sepa_unsupported_account: 'Sepa unsupported account',
  shipping_calculation_failed: 'Shipping calculation failed',
  sku_inactive: 'Sku inactive',
  state_unsupported: 'State unsupported',
  tax_id_invalid: 'Tax id invalid',
  taxes_calculation_failed: 'Taxes calculation failed',
  testmode_charges_only: 'Testmode charges only',
  tls_version_unsupported: 'Tls version unsupported',
  token_already_used: 'Token already used',
  token_in_use: 'Token in use',
  transfers_not_allowed: 'Transfers not allowed',
  upstream_order_creation_failed: 'Upstream order creation failed',
  url_invalid: 'Url invalid',
  start_month: 'Start Month',
  end_month: 'End Month',
  to: 'To',
  start_date: 'Start date',
  end_date: 'End date',
  date_format: 'MM/DD/YYYY',
  can_not_get_vpc: 'Can not find out VPC',
  timezone: 'Timezone',
  select_timezone: 'Select Timezone',
  invalid_timezone_type: 'Invalid timezone type',
  invalid_language_type: 'Invalid language type',
  invalid_intake_host_configuration: 'Invalid intake host configuration',
  error_connecting_to_dataDog: 'Error connecting to DataDog',
  no_token_provided: 'No token provided.',
  session_cookie_does_not_set: 'Session Cookie does not set',
  you_cant_change_the_plan_the_account_select_same_plan:
    'You are using enterprise plan or select same plan as current one',
  invalid_email_address_format: 'Invalid email address format',
  can_not_find_out_administrator: 'Can not find out administrator',
  can_not_update_Profile: 'Can not update Profile!',
  can_not_get_list_member: 'Can not get list member!',
  can_not_add_member: 'Can not add member!',
  can_not_list_members: 'Can not list members',
  can_not_remove_member: 'Can not remove member!',
  can_not_change_primary_owner: 'Can not be transferred Primary Owner',
  can_not_create_account: 'Can not create account!',
  can_not_update_account: 'Can not update account!',
  can_not_remove_account: 'Can not remove account!',
  can_not_get_list_network: 'Can not get list network',
  can_not_create_network: 'Can not create network',
  can_not_update_network_information: 'Cannot update network information',
  can_not_update_network: 'Can not update network',
  can_not_destroy_network: 'Can not destroy network',
  can_not_create_cluster: 'Can not create cluster',
  can_not_update_cluster: 'Can not update cluster',
  can_not_create_node: 'Can not create node',
  can_not_get_list_network_accounts: 'Can not get list network accounts',
  can_not_find_out_invoice: 'Can not find out invoice',
  can_not_find_out_cluster: 'Can not find out cluster',
  can_not_find_out_node: 'Can not find out node',
  can_not_register_address: 'Can not register address!',
  can_not_update_contact: 'Can not update contact',
  account_is_not_exist: 'Account is not exist!',
  can_not_find_out_network: 'Can not find out network!',
  can_not_find_out_cluster_to_update: 'Can not find out cluster to update!',
  can_not_find_out_network_to_delete: 'Can not find out network to delete!',
  can_not_find_out_cluster_to_delete: 'Can not find out cluster to delete!',
  can_not_add_node: 'Can not add Node!',
  can_not_find_out_network_to_update: 'Can not find out network to update!',
  can_not_find_out_node_to_update: 'Can not find out node to update!',
  can_not_update_node: 'Can not update Node!',
  can_not_find_out_node_to_delete: 'Can not find out node to delete!',
  network_does_not_exist: 'Network does not exist',
  sender_account_does_not_exist: 'Sender account does not exist',
  receiver_account_does_not_exist: 'Receiver account does not exist',
  target_node_does_not_exist: 'Target node does not exist',
  user_profile_does_not_exist: 'User profile does not exist!',
  cannot_save_network_because_user_profile_does_not_exist: 'Failed to change the selected network',
  cannot_save_user_profile_because_user_profile_does_not_exist: 'Failed to update the profile',
  admin_profile_does_not_exist: 'Admin profile does not exist!',
  node_does_not_exist: 'Node does not exist',
  account_UUID_does_not_exist: 'Account UUID does not exist',
  proposed_node_does_not_exist: 'Proposed node does not exist',
  network_already_exist_in_account: 'Network already exist in account',
  invitation_does_not_exist: 'Invitation does not exist',
  receiver_account_was_consortium_already: 'Receiver account was consortium already',
  update_consortium_role_success: 'Consortium role registered successfully',
  withdraw_consortium_success: 'Consortium withdrawal successfully',
  consortium_does_not_exist: 'Consortium does not exist',
  cannot_remove_last_consortium_admin: 'Cannot remove last consortium administrator',
  invitation_was_already_responsed: 'Invitation was already responsed',
  invitation_was_canceled: 'Invitation was canceled',
  query_result_is_empty: 'query result is empty',
  alert_does_not_exist: 'Alert does not exist',
  alert_already_set_read: 'Alert already set read',
  possible_0_validator_node_can_not_send_proposal:
    'Possible 0 Validator node, can not send proposal',
  admin_does_not_exist: 'Admin does not exist!',
  failed_to_finded_user_profile_of_specified_uid: 'Failed to finded user profile of specified uid',
  add_peer_failed: 'Failed to peering',
  ssh_prepare_failed: 'Prepare ssh connection failed',
  language_of_notification_emails: 'Language of notification emails',
  accountant_Email: 'Accountant Email',
  system_admin_Email: 'System Administrator Email',
  withdrawn: 'Withdrawn',
  can_not_check_block_number:
    'Unable to confirm that latest block number is greater than or equal to specified number',
  the_list_of_validators_is_not_synced: 'The list of Signers is not synced',
  the_latest_block_is_not_synced: 'The latest block is not synced',
  failed_to_get_latest_block_info_from_nodes: 'Failed to get latest block info from nodes',
  invalid_network_id_specified: 'Invalid Network ID specified',
  invalid_chain_id_specified: 'Invalid Chain ID specified',
  invalid_gas_price_specified: 'Invalid Gas Price specified',
  too_long_description_length: 'Please enter description of up to 1000 characters',
  can_not_get_cluster_removal_status: 'Can not get cluster removal status',
  can_not_get_node_removal_status: 'Can not get node removal status',
  warn_maybe_not_synced_network:
    'There was no confirmation that this operation is safe, either because the majority of Validator nodes did not respond or their block status is not up to date. Do you still want to continue? (Deletion may result in inconsistent network state)',
  invalid_uuid_format: 'Invalid UUID format',
  invalid_domain_name_format: 'Invalid Domain Name format',
  invalid_cert_arn_format: 'Invalid Certificate ARN format',
  invalid_account_uuid_format: 'Invalid Account UUID format',
  the_network_is_in_the_process_of_force_deleting:
    'The network is in the process of force deleting',
  the_cluster_is_in_the_process_of_force_deleting:
    'The cluster is in the process of force deleting',
  the_node_is_in_the_process_of_force_deleting: 'The node is in the process of force deleting',
  detection_of_stacked_resource_is_disabled: 'Detection of stacked resource is disabled',
  the_network_has_stacked_and_force_deleting_resource:
    'The network has "stacked and already started force deleting" resource',
  can_not_change_the_last_signer_to_tx: 'Can not change the last Validator node to Relay node',
  display_name_too_long: 'Display name is too long',
  invalid_email_format: 'Invalid email format',
  network_name_too_short: 'Network name is too short',
  network_name_too_long: 'Network name is too long',
  cluster_name_too_short: 'Cluster name is too short',
  cluster_name_too_long: 'Cluster name is too long',
  number_of_node_too_small: 'Number of node is too small',
  number_of_node_too_large: 'Number of node is too large',
  network_description_too_long: 'Network description is too long',
  node_name_too_short: 'Node name is too short',
  node_name_too_long: 'Node name is too long',
  node_size_too_large: 'Node size is too large',
  can_not_get_expand_info_of_volume: 'Can not find expand information of data volume',
  database_write_conflict:
    'Unable to operate because the database is busy. Please wait for a while and try again.',
  only_the_consortium_administrator_can_update_network_option:
    'Only the consortium administrator can update network option',
  update_network_option_success: 'Network option updated successfully',
  can_not_update_network_option: 'Can not update network option',
  Cannot_change_to_PrimaryOwner_if_change_permission_is_not_owner:
    'Cannot be transferred to Primary Owner if change permission is not owner',
  // Registration Modal
  howToRegisterAccount: 'How to register a new account',
  registration_title: 'For first-time registration',
  registration_content:
    'If you are a first time user of G.U.Blockchain Cloud and already have a Google account, please select [Login with Google Account] and your account will be automatically registered. In case you will use an e-mail address other than your Google account, please click the [Login with Email Address] button and enter the e-mail address you want to register. Name and password input box will display for the first time registration. Please perform the initial registration from that.',
  // Contact Email
  contact_email: 'Contact Email',
  input_contact_email: 'Input Contact Email',
  // Tab Titles
  nodes_title: 'Nodes',
  cluster_overview_title: 'Cluster Overview',
  cluster_security_title: 'Cluster Security',
  cluster_domain_title: 'Cluster Domain',
  block_explorer_overview_title: 'Block Explorer Overview',
  block_explorer_security_title: 'Block Explorer Security',
  block_explorer_metrics_title: 'Block Explorer Metrics',
  node_overview_title: 'Node Overview',
  node_security_title: 'Node Security',
  node_metrics_title: 'Node Metrics',
  node_logs_title: 'Node Logs',
  blockchain_network_overview: 'Blockchain Network Overview',
  member_access: 'Access',
  can_not_add_custom_domain: 'Can not add custom domain',
  domain: 'Domain',
  add_custom_domain: 'Add Custom Domain',
  domain_list: 'Domain List',
  domain_name: 'Domain Name',
  input_domain_name: 'Input Domain Name',
  target_domain: 'Target Domain',
  add_domain: 'Add Domain',
  delete_domain: 'Delete Domain',
  registration_information: 'Registration Information',
  please_include_the_information_for_the_custom_domain_you_wish_to_add:
    'Please include the information for the custom domain you wish to add.',
  you_want_to_delete_the_custom_domain: 'You want to delete the custom domain?',
  pending_validation: 'Pending',
  other: 'Other',
  custom_domain_info: 'Custom Domain Info',
  custom_domain_description:
    'Please set CNAME in the DNS settings of the service that acquired the domain.',
  for_authentication: 'For Authentication',
  for_registration: 'For Registration',
  the_domain_name_is_already_registered: 'The domain name is already registered',
  view: 'view',
  cluster_is_being_created_or_deleted:
    'The cluster is not available because it is being created or deleted',
  can_not_add_block_explorer: 'Can not add block explorer',
  can_not_remove_block_explorer: 'Can not remove block explorer',
  block_explorer_not_found_or_not_empty: 'Block explorer not found or not empty',
  delete_block_explorer: 'Delete Block Explorer',
  are_you_sure_you_want_to_delete_this_block_exp:
    'Are you sure you want to delete this block explorer?',
  change_node_version: ' Change Node Version',
  enable_rebuild_node: 'Enable rebuild node',
  rebuild_node: 'Rebuild Node',
  are_you_sure_you_want_to_downgrade_this_node:
    'Node downgrading requires to rebuild to reset the network data and refetch from other nodes.',
  are_you_sure_you_want_to_rebuild_this_node:
    'Rebuild node will reset the network data and refetch from other nodes.\nAre you sure you want to rebuild this node?',
  update_block_exp_started: 'Block explorer updated started',
  can_not_update_block_exp: 'Can not update block explorer',
  re_captcha_secret_key: 'reCAPTCHA Secret Key',
  re_captcha_client_key: 'reCAPTCHA Site Key',
  update_block_exp: 'Update Block Explorer',
  update_and_rebuild: 'Update & Rebuild',
  two_factor_auth: '2FA',
  two_factor_auth_guide:
    'To activate 2FA, please login to your {{value}} and go to "Settings" > "Security" to register your phone number.',
  rebuild_block_exp: ' Rebuild Block Explorer',
  clean_blockscout_db: ' Clean Blockscout database',
  are_you_sure_you_want_to_clean_db:
    'Clean Blockscout database will reset the data and refetch from the archive node.\nAre you sure you want to clean Blockscout database?',
  block_exp_type: 'Block Explorer type',
  change_block_gas_limit: ' Change Block Gas Limit',
  update_gas_limit_start: 'Update the block gas limit started',
  can_not_update_block_gas_limit: 'Unable to update the block gas limit',
  change_instance_type: 'Change Instance Type',
  change_instance_type_started: 'Change instance type started',
  apply_latest_genesis_config: 'Apply latest genesis config',
  target_block: 'Target Block',
  hard_fork: 'Hard Fork',
  latest_hard_fork: 'Latest Hard Fork',
  genesis_json: 'genesis.json',
  genesis_config: 'Genesis Config',
  create_hard_fork_proposal: 'Create Hard Fork Proposal',
  create_hard_fork_proposal_successfully: 'Create hard fork proposal successfully',
  latest_block: 'Latest Block',
  scheduling_hard_fork: 'scheduling hard fork',
  can_not_update_latest_hard_fork: 'Unable to update the latest hard fork',
  update_latest_hard_fork_start: 'Update the latest hard fork start',
  period_reach_target_block: 'Active period after approved',
  number_of_incompatible_validators: 'Num of Incompatibale Validators',
  incompatibility: 'Incompatibility',
  compatibility: 'Compatibility',
  compatible_nodes: 'Compatible Nodes',
  many_node_incompatible_geth_version: 'Too many nodes have incompatible geth versions.',
  retry: 'Retry',
  retry_apply_hard_fork_success: 'Retry apply hard fork to network success',
  retry_apply_hard_fork_fail: 'Retry apply hard fork to network failed',
  hard_fork_status: 'Hard Fork Status',
  done: 'Done',
  failed: 'Failed',
  syncing: 'Syncing',
  create_hard_fork_node_not_ready_warning:
    'There are some nodes that are not ready for the hard fork. Are you sure to create a hard fork?',
  allow_unprotected_txs: 'Allow unprotected (non EIP155 signed) transactions',
  receipt_from: 'Receipt from {{ name }}',
  receipt: 'Receipt',
  amount_paid: 'Amount paid',
  date_paid: 'Payment date and time',
  payment_method: 'Payment method',
  summary: 'summary',
  receipt_contact: 'If you have any questions, contact us at {{ email }}',
  receipt_wrong_email: 'Something wrong with the email?',
  view_browser: 'View it in your browser.',
  invoice: 'Invoice',
  date_of_issue: 'Date of issue',
  bill_to: 'Bill to',
  qty: 'Qty',
  amount_due: 'Amount due',
  view_invoice: 'View invoice',
  view_receipt: 'View receipt',
  qualified_invoice_issuer_registration_number: 'JP TRN',
  pre_tax_amount: 'Pre-tax amount',
  tax_inclusive_amount: 'Tax-inclusive amount',
  business_name: 'G.U. Technologies, Inc.',
  dealing_date: 'Dealing date',
  date_of_issue_at: '{{date, MMM D, YYYY}}',
  date_paid_at: '{{date, MMM D, YYYY h:mm:ss A}}',
  invoice_amount: 'Amount',
  business_postal_code: '150-8512, Japan',
  business_city: '26-1 Sakuragaokacho, Shibuya-ku, Tokyo',
  business_addr: 'Level 15, Cerulean Tower',
  required_block_gas_limit: 'The Block Gas Limit is required field',
  get_geneis_json_failed: 'Unable to retrive the genesis.json',
  new_proposal: 'New Proposal',
  waiting: 'Waiting',
  next_epoch_block: 'Next Epoch Block',
  discard: 'Discard',
  be_proposed_by_other_nodes: 'Be proposed by other nodes',
  proposing_other_nodes: 'Proposing other nodes',
  in_geth_local: 'In Geth local',
  in_network: 'In Network',
  my_node: 'My Node',
  go_to_proposal_history: 'Go to proposal history',
  go_to_latest_proposals: 'Back to latest proposals',
  waitting_import_new_proposals: 'Waitting import latest proposals',
  all_proposals_reset: 'All proposals are reset',
  block: 'Block',
  validator: 'Validator',
  relay: 'Relay',
  normal: 'Normal',
  filter_end_block_more_than_start_block: 'To Block must be more than Start Block',
  from_block: 'From Block',
  to_block: 'To Block',
  address: 'Address',
  send_proposal_from: 'Send proposal from',
  for_target_node_address: 'For target node address',
  node_status: 'Node Status',
  network_porposal_status: 'Network Proposal Status',
  geth_local_porposal_status: 'Geth Local Proposal Status',
  discard_proposal: 'Discard Proposal',
  discard_proposal_content:
    'Discard proposal will discard your vote in the network at the next epoch block.',
  epoch_block: 'Epoch Block',
  input_epoch_block: 'Input Epoch Block',
  create_proposal: 'Create Proposal',
  external_transfer_address: 'Transfer Address',
  connect_to_external_clef: 'Connect to external clef',
  signer_address: 'Signer Address',
  input_signer_address: 'Input signer address',
  clef_server_username: 'Username',
  input_clef_server_username: 'Input clef server username',
  clef_server_host: 'Hostname/IP Address',
  input_clef_server_host: 'Input clef server hostname or ip address',
  clef_api_port: 'API Port',
  input_clef_api_port: 'Input API Port',
  external_signer_address: 'External Signer Address',
  external_signer_api_port: 'External Signer API Port',
  clef: 'Clef',
  update_external_clef: 'Update External Clef',
  x_warn_not_connected_to_external_clef: 'Not connected to external clef',
  connected: 'Connected',
  connecting: 'Connecting ...',
  not_connected: 'Not Connected',
  invalid_external_server_host: 'Invalid hostname',
  can_not_update_external_clef: 'Unable to update the external clef',
  update_external_clef_success: 'Update the external clef successfully',
  external_signer_server: 'External Signer Server',
  ssh_private_key_file: 'SSH Private key file',
  ssh_private_key_too_large: 'Private key file is too large',
  invalid_ssh_private_key: 'Invalid ssh private key',
  available_when_create_one_node: 'only available when create 1 node',
  invalid_supported_card_number: 'The JCB card is not supported',
  small_v1: 'Small (v1)',
  medium_v1: 'Medium (v1)',
  large_v1: 'Large (v1)',
  xlarge_v1: 'XLarge (v1)',
  '2xlarge_v1': '2XLarge (v1)',
  small_v2: 'Small (v2)',
  medium_v2: 'Medium (v2)',
  large_v2: 'Large (v2)',
  business_v2: 'Business (v2)',
  businesslarge_v2: 'Business Large (v2)',
  mega_v2: 'Mega (v2)',
  metal_v2: 'Metal (Max TPS) (v2)',
  small_v3: 'Small (v3)',
  medium_v3: 'Medium (v3)',
  large_v3: 'Large (v3)',
  business_v3: 'Business (v3)',
  businesslarge_v3: 'Business Large (v3)',
  mega_v3: 'Mega (v3)',
  metal_v3: 'Metal (Max TPS) (v3)',
  block_explorer_version: 'Block Explorer Version',
  inactive_user_warning:
    'This member may be locked or having problems, please contact the administrator for help.',
  unknown_member: 'Unknown member',
  wallet_connect_project_id: 'Wallet connect project ID',
  upgrade_block_explorer_version: 'Upgrade Block Explorer Version',
  enable_frontend: 'Enable blockscout UI V2',
  network_RPC_endpoint: 'Network RPC endpoint',
  select_block_exp_backend_version: 'Select backend version',
  select_block_exp_frontend_version: 'Select frontend version',
  input_wallet_connect_project_id: 'Input wallet connect project ID',
  input_network_RPC_endpoint: 'Input network RPC endpoint',
  input_re_captcha_secret_key: 'Input reCAPTCHA secret key',
  input_re_captcha_client_key: 'Input reCAPTCHA client key',
  network_RPC_endpoint_description:
    'The RPC endpoint of the network. This is used to connect to the network in block explorer. Make sure the port is open.\
     You also use other network RPC endpoint by update block explorer.',
  input_network_RPC_endpoint_description:
    'The RPC endpoint of the network. This is used to connect to the network. Make sure the port is open.',
  BLOCK_EXPLORER: 'Block Explorer',
  HTTP_RPC: 'HTTP RPC',
  WS_RPC: 'WS RPC',
  edit_listeners: 'Edit Listeners',
  duplicate_listener_rule: '{{ service }} service duplicate listener rule',
  exist_many_port_each_service: '{{ service }} service has many listener port',
  explorer_endpoint_notification: 'To use the Block Explorer Endpoint, the port must be open.',
  explorer_endpoint_notification_for_member:
    'To use the Block Explorer Endpoint, the port must be open by authorized user.',
  duplicate_listener_port: 'Duplicate listener port',
  block_explorer_port_change_warning:
    'Changing Block Explorer listener port may require rebuilding Block Explorer. Are you ready to proceed with the update?',
  confirm_update_listeners: 'Confirm update listeners',
  port: 'Port',
  select_service: 'Select service',
  select_port: 'Select port',
  add_listener: 'Add Listener',
  block_explorer_not_specified: 'Block Explorer not specified.',
  validator_node: 'Validator Node',
  relay_node: 'Relay Node',
  num_of_validators: 'Num of Validators',
};
