import { createTypeAction, createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import { IStore } from '~/stores/configure-store';
import MemberGApiClient from './gapi/member-gapi-client';
export * from './gapi/query/network';
import * as QueryNetwork from './gapi/query/network';
import * as QueryProposal from './gapi/query/proposal';
import { INetwork } from '~/types/network-types';
import * as QueryInvitation from './gapi/query/invitation';
import {
  MutationUpdateNodeVersionArgs,
  MutationUpdateBlockExplorerArgs,
  MutationUpdateNodeExplorerVersionArgs,
  MutationUpdateNodeInstanceTypeArgs,
  MutationUpdateBlockExplorerInstanceTypeArgs,
  MutationVoteHardForkProposalArgs,
  QueryListHardForkProposalsArgs,
  MutationCreateHardForkProposalArgs,
  MutationApplyHardForkToNodeArgs,
  MutationApplyHardForkToBlockExplorerArgs,
  MutationRetryApplyHardForkToNetworkArgs,
  MutationUpdateBlockGasLimitArgs,
  QueryGetGenesisJsonArgs,
  QueryGetGenesisJsonInExplorerArgs,
  MutationDiscardProposalArgs,
  MutationSendProposalArgs,
  QueryListProposalHistoryArgs,
  MutationUpdateExternalClefArgs,
  MutationUpdateBlockExplorerVersionArgs,
  MutationSetEndpointListenerArgs,
} from '~/gapi/gtypes';

export * from './gapi/query/invitation';
export * from './gapi/query/proposal';

export const listProviders = createTypeAsyncAction(
  'LIST_PROVIDERS',
  async (args: QueryNetwork.QueryListProvidersArgs) => {
    const client = new MemberGApiClient({});
    return client.listProviders(args);
  },
);

export const listNetworks = createTypeAsyncAction(
  'LIST_NETWORKS',
  async (args: QueryNetwork.QueryListNetworksArgs) => {
    const client = new MemberGApiClient({});
    return client.listNetworks(args);
  },
);

export const selectNetwork = createTypeAsyncAction(
  'SELECT_NETWORK',
  async (network: INetwork) => network,
);

export const createNetwork = createTypeAsyncAction(
  'CREATE_NETWORK',
  async (args: QueryNetwork.MutationCreateNetworkArgs) => {
    const client = new MemberGApiClient({});
    return client.createNetwork(args);
  },
);

export const updateNetwork = createTypeAsyncAction(
  'UPDATE_NETWORK',
  async (args: QueryNetwork.MutationUpdateNetworkArgs) => {
    const client = new MemberGApiClient({});
    return client.updateNetwork(args);
  },
);

export const destroyNetwork = createTypeAsyncAction(
  'DESTROY_NETWORK',
  async (args: QueryNetwork.MutationDestroyNetworkArgs) => {
    const client = new MemberGApiClient({});
    return client.destroyNetwork(args);
  },
);

export const createCluster = createTypeAsyncAction(
  'CREATE_CLUSTER',
  async (args: QueryNetwork.MutationCreateClusterArgs) => {
    const client = new MemberGApiClient({});
    return client.createCluster(args);
  },
);

export const updateCluster = createTypeAsyncAction(
  'UPDATE_CLUSTER',
  async (args: QueryNetwork.MutationUpdateClusterArgs) => {
    const client = new MemberGApiClient({});
    return client.updateCluster(args);
  },
);

export const deleteCluster = createTypeAsyncAction(
  'DELETE_CLUSTER',
  async (args: QueryNetwork.MutationDeleteClusterArgs) => {
    const client = new MemberGApiClient({});
    return await client.deleteCluster(args);
  },
);

export const createNode = createTypeAsyncAction(
  'CREATE_NODE',
  async (args: QueryNetwork.MutationCreateNodeArgs) => {
    const client = new MemberGApiClient({});
    return client.createNode(args);
  },
);

export const updateNode = createTypeAsyncAction(
  'UPDATE_NODE',
  async (args: QueryNetwork.MutationUpdateNodeArgs) => {
    const client = new MemberGApiClient({});
    return client.updateNode(args);
  },
);

export const deleteNode = createTypeAsyncAction(
  'DELETE_NODE',
  async (args: QueryNetwork.MutationDeleteNodeArgs) => {
    const client = new MemberGApiClient({});
    return await client.deleteNode(args);
  },
);

export const controlNode = createTypeAsyncAction(
  'CONTROL_NODE',
  async (args: QueryNetwork.MutationControlNodeArgs) => {
    const client = new MemberGApiClient({});
    return await client.controlNode(args);
  },
);

export const controlBlockExplorer = createTypeAsyncAction(
  'CONTROL_BLOCK_EXPLORER',
  async (args: QueryNetwork.MutationControlBlockExplorerArgs) => {
    const client = new MemberGApiClient({});
    return await client.controlBlockExplorer(args);
  },
);

export const grantNetworkRole = createTypeAsyncAction(
  'GRANT_NETWORK_ROLE',
  async (args: QueryNetwork.MutationGrantNetworkRoleArgs) => {
    const client = new MemberGApiClient({});
    return client.grantNetworkRole(args);
  },
);

export const revokeNetworkRole = createTypeAsyncAction(
  'REVOKE_NETWORK_ROLE',
  async (args: QueryNetwork.MutationRevokeNetworkRoleArgs) => {
    const client = new MemberGApiClient({});
    return client.revokeNetworkRole(args);
  },
);

export const listNetworkAccesses = createTypeAsyncAction(
  'LIST_NETWORK_ACCESSES',
  async (args: QueryNetwork.QueryListNetworkAccessesArgs) => {
    const client = new MemberGApiClient({});
    return client.listNetworkAccesses(args);
  },
);

export const listInvitations = createTypeAsyncAction(
  'LIST_INVITATIONS',
  async (args: QueryInvitation.QueryListInvitationsArgs, store: IStore) => {
    const { invitationList } = store.appState;
    const client = new MemberGApiClient({});
    return client.listInvitations({
      ...args,
      page: {
        pageIndex: invitationList.pageInfo.pageIndex,
        pageSize: invitationList.pageInfo.pageSize,
        ...args.page,
      },
    });
  },
);

export const sendInvitation = createTypeAsyncAction(
  'SEND_INVITATION',
  async (args: QueryInvitation.MutationSendInvitationArgs) => {
    const client = new MemberGApiClient({});
    return client.sendInvitation(args);
  },
);

export const responseInvitation = createTypeAsyncAction(
  'RESPONSE_INVITATION',
  async (args: QueryInvitation.MutationResponseInvitationArgs) => {
    const client = new MemberGApiClient({});
    return client.responseInvitation(args);
  },
);

export const cancelInvitation = createTypeAsyncAction(
  'CANCEL_INVITATION',
  async (args: QueryInvitation.MutationCancelInvitationArgs) => {
    const client = new MemberGApiClient({});
    return client.cancelInvitation(args);
  },
);

export const getNetwork = createTypeAsyncAction(
  'GET_NETWORK',
  async (args: QueryNetwork.QueryGetNetworkArgs) => {
    const client = new MemberGApiClient({});
    return client.getNetwork(args);
  },
);

export const getNetworkRole = createTypeAsyncAction(
  'GET_NETWORK_ROLE',
  async (args: QueryNetwork.QueryGetNetworkRoleArgs) => {
    const client = new MemberGApiClient({});
    return client.getNetworkRole(args);
  },
);

export const findClusters = createTypeAsyncAction(
  'FIND_CLUSTERS',
  async (args: QueryNetwork.QueryFindClustersArgs) => {
    const client = new MemberGApiClient({});
    return client.findClusters(args);
  },
);

export const findNodes = createTypeAsyncAction(
  'FIND_NODES',
  async (args: QueryNetwork.QueryFindNodesArgs) => {
    const client = new MemberGApiClient({});
    return client.findNodes(args);
  },
);

export const getSigners = createTypeAsyncAction(
  'GET_SIGNERS',
  async (args: QueryProposal.QueryGetSignersArgs) => {
    const client = new MemberGApiClient({});
    return client.getSigners(args);
  },
);

export const getProposalStatus = createTypeAsyncAction(
  'GET_PROPOSAL_STATUS',
  async (args: QueryProposal.QueryGetProposalStatusArgs) => {
    const client = new MemberGApiClient({});
    return client.getProposalStatus(args);
  },
);

export const listEndpointRestrictions = createTypeAsyncAction(
  'LIST_ENDPOINT_RESTRICTIONS',
  async (args: QueryNetwork.QueryListEndpointRestrictionsArgs) => {
    const client = new MemberGApiClient({});
    return client.listEndpointRestrictions(args);
  },
);

export const listExplorerRestrictions = createTypeAsyncAction(
  'LIST_EXPLORER_RESTRICTIONS',
  async (args: QueryNetwork.QueryListExplorerRestrictionsArgs) => {
    const client = new MemberGApiClient({});
    return client.listExplorerRestrictions(args);
  },
);

export const listNodeRestrictions = createTypeAsyncAction(
  'LIST_NODE_RESTRICTIONS',
  async (args: QueryNetwork.QueryListNodeRestrictionsArgs) => {
    const client = new MemberGApiClient({});
    return client.listNodeRestrictions(args);
  },
);

export const setEndpointRestriction = createTypeAsyncAction(
  'SET_ENDPOINT_RESTRICTION',
  async (args: QueryNetwork.MutationSetEndpointRestrictionArgs) => {
    const client = new MemberGApiClient({});
    return client.setEndpointRestriction(args);
  },
);

export const setExplorerRestriction = createTypeAsyncAction(
  'SET_EXPLORER_RESTRICTION',
  async (args: QueryNetwork.MutationSetExplorerRestrictionArgs) => {
    const client = new MemberGApiClient({});
    return client.setExplorerRestriction(args);
  },
);

export const setNodeRestriction = createTypeAsyncAction(
  'SET_NODE_RESTRICTION',
  async (args: QueryNetwork.MutationSetNodeRestrictionArgs) => {
    const client = new MemberGApiClient({});
    return client.setNodeRestriction(args);
  },
);

export const expandNodeVolume = createTypeAsyncAction(
  'EXPAND_NODE_VOLUME',
  async (args: QueryNetwork.MutationExpandNodeVolumeArgs) => {
    const client = new MemberGApiClient({});
    return client.expandNodeVolume(args);
  },
);

export const expandBlockExplorerVolume = createTypeAsyncAction(
  'EXPAND_BLOCK_EXPLORER_VOLUME',
  async (args: QueryNetwork.MutationExpandBlockExplorerVolumeArgs) => {
    const client = new MemberGApiClient({});
    return client.expandBlockExplorerVolume(args);
  },
);

export const getNodeMetrics = createTypeAsyncAction(
  'GET_NODE_METRICS',
  async (args: QueryNetwork.QueryGetNodeMetricsArgs) => {
    const client = new MemberGApiClient({});
    return client.getNodeMetrics(args);
  },
);

export const getNodeLog = createTypeAsyncAction(
  'GET_NODE_LOG',
  async (args: QueryNetwork.QueryGetNodeLogArgs) => {
    const client = new MemberGApiClient({});
    return client.getNodeLog(args);
  },
);

export const getExplorerMetrics = createTypeAsyncAction(
  'GET_EXPLORER_METRICS',
  async (args: QueryNetwork.QueryGetExplorerMetricsArgs) => {
    const client = new MemberGApiClient({});
    return client.getExplorerMetrics(args);
  },
);

export const getExplorerLog = createTypeAsyncAction(
  'GET_EXPLORER_LOG',
  async (args: QueryNetwork.QueryGetExplorerLogArgs) => {
    const client = new MemberGApiClient({});
    return client.getExplorerLog(args);
  },
);

export const sendAllEtherFromNode = createTypeAsyncAction(
  'SEND_ALL_ETHER_FROM_NODE',
  async (args: QueryNetwork.MutationSendAllEtherFromNodeArgs) => {
    const client = new MemberGApiClient({});
    return client.sendAllEtherFromNode(args);
  },
);

export const estimateTransferGas = createTypeAsyncAction(
  'ESTIMATE_TRANSFER_GAS',
  async (args: QueryNetwork.QueryEstimateTransferGasArgs) => {
    const client = new MemberGApiClient({});
    return client.estimateTransferGas(args);
  },
);

export const checkClusterRemovalStatus = createTypeAsyncAction(
  'CHECK_CLUSTER_REMOVAL_STATUS',
  async (args: QueryNetwork.QueryCheckClusterRemovalStatusArgs) => {
    const client = new MemberGApiClient({});
    return client.checkClusterRemovalStatus(args);
  },
);

export const checkNodeRemovalStatus = createTypeAsyncAction(
  'CHECK_NODE_REMOVAL_STATUS',
  async (args: QueryNetwork.QueryCheckNodeRemovalStatusArgs) => {
    const client = new MemberGApiClient({});
    return client.checkNodeRemovalStatus(args);
  },
);

export const getTxpoolStatus = createTypeAsyncAction(
  'GET_TXPOOL_STATUS',
  async (args: QueryNetwork.QueryGetTxpoolStatusArgs) => {
    const client = new MemberGApiClient({});
    return client.getTxpoolStatus(args);
  },
);

export const listConsortiums = createTypeAsyncAction(
  'LIST_CONSORTIUMS',
  async (args: QueryNetwork.QueryListConsortiumsArgs) => {
    const client = new MemberGApiClient({});
    return client.listConsortiums(args);
  },
);

export const withdrawConsortium = createTypeAsyncAction(
  'WITHDRAWAL_CONSORTIUM',
  async (args: QueryNetwork.MutationWithdrawConsortiumArgs) => {
    const client = new MemberGApiClient({});
    return client.withdrawConsortium(args);
  },
);

export const updateConsortiumRole = createTypeAsyncAction(
  'UPDATE_CONSORTIUM_ROLE',
  async (args: QueryNetwork.MutationUpdateConsortiumRoleArgs) => {
    const client = new MemberGApiClient({});
    return client.updateConsortiumRole(args);
  },
);

export const updateNetworkOption = createTypeAsyncAction(
  'UPDATE_NETWORK_OPTION',
  async (args: QueryNetwork.MutationUpdateNetworkOptionArgs) => {
    const client = new MemberGApiClient({});
    return client.updateNetworkOption(args);
  },
);

export const listCustomDomains = createTypeAsyncAction(
  'LIST_CUSTOM_DOMAINS',
  async (args: QueryNetwork.QueryListCustomDomainsArgs) => {
    const client = new MemberGApiClient({});
    return client.listCustomDomains(args);
  },
);

export const addCustomDomain = createTypeAsyncAction(
  'ADD_CUSTOM_DOMAIN',
  async (args: QueryNetwork.MutationAddCustomDomainArgs) => {
    const client = new MemberGApiClient({});
    return client.addCustomDomain(args);
  },
);

export const removeCustomDomain = createTypeAsyncAction(
  'REMOVE_CUSTOM_DOMAIN',
  async (args: QueryNetwork.MutationRemoveCustomDomainArgs) => {
    const client = new MemberGApiClient({});
    return client.removeCustomDomain(args);
  },
);

export const checkCustomDomainStatus = createTypeAsyncAction(
  'CHECK_CUSTOM_DOMAIN_STATUS',
  async (args: QueryNetwork.MutationCheckCustomDomainStatusArgs) => {
    const client = new MemberGApiClient({});
    return client.checkCustomDomainStatus(args);
  },
);

export const createBlockExplorerAction = createTypeAsyncAction(
  'CREATE_BLOCK_EXPLORER',
  async (args: QueryNetwork.MutationToCreateBlockExplorerArgs) => {
    const client = new MemberGApiClient({});
    return client.createBlockExplorer(args);
  },
);

export const deleteBlockExplorerAction = createTypeAsyncAction(
  'DELETE_BLOCK_EXPLORER',
  async (args: QueryNetwork.MutationDeleteBlockExplorerArgs) => {
    const client = new MemberGApiClient({});
    return client.deleteBlockExplorer(args);
  },
);

export const updateNodeVersion = createTypeAsyncAction(
  'UPDATE_NODE_VERSION_RESULT_TYPE',
  async (args: MutationUpdateNodeVersionArgs) => {
    const client = new MemberGApiClient({});
    return client.updateNodeVersion(args);
  },
);

export const updateBlockExplorer = createTypeAsyncAction(
  'UPDATE_BLOCK_EXP_RESULT_TYPE',
  async (args: MutationUpdateBlockExplorerArgs) => {
    const client = new MemberGApiClient({});
    return client.updateBlockExplorer(args);
  },
);

export const updateNodeExplorerVersion = createTypeAsyncAction(
  'UPDATE_NODE_EXPLORER_VERSION_RESULT_TYPE',
  async (args: MutationUpdateNodeExplorerVersionArgs) => {
    const client = new MemberGApiClient({});
    return client.updateNodeExplorerVersion(args);
  },
);

export const createHardForkProposal = createTypeAsyncAction(
  'CREATE_HARD_FORK_PROPOSAL',
  async (args: MutationCreateHardForkProposalArgs) => {
    const client = new MemberGApiClient({});
    return client.createHardForkProposal(args);
  },
);

export const voteHardForkProposal = createTypeAsyncAction(
  'VOTE_HARD_FORK_PROPOSAL',
  async (args: MutationVoteHardForkProposalArgs) => {
    const client = new MemberGApiClient({});
    return client.voteHardForkProposal(args);
  },
);

export const listHardForkProposals = createTypeAsyncAction(
  'LIST_HARD_FORK_PROPOSAL',
  async (args: QueryListHardForkProposalsArgs) => {
    const client = new MemberGApiClient({});
    return client.listHardForkProposals(args);
  },
);

export const applyHardForkToNode = createTypeAsyncAction(
  'APPLY_HARD_FORK_TO_NODE',
  async (args: MutationApplyHardForkToNodeArgs) => {
    const client = new MemberGApiClient({});
    return client.applyHardForkToNode(args);
  },
);

export const applyHardForkToBlockExplorer = createTypeAsyncAction(
  'APPLY_HARD_FORK_EXPLORER',
  async (args: MutationApplyHardForkToBlockExplorerArgs) => {
    const client = new MemberGApiClient({});
    return client.applyHardForkToBlockExplorer(args);
  },
);

export const retryApplyHardForkToNetwork = createTypeAsyncAction(
  'RETRY_HARD_FORK_NETWORK',
  async (args: MutationRetryApplyHardForkToNetworkArgs) => {
    const client = new MemberGApiClient({});
    return client.retryApplyHardForkToNetwork(args);
  },
);

export const updateBlockGasLimit = createTypeAsyncAction(
  'UPDATE_GAS_LIMIT_RESULT_TYPE',
  async (args: MutationUpdateBlockGasLimitArgs) => {
    const client = new MemberGApiClient({});
    return client.updateBlockGasLimit(args);
  },
);

export const updateNodeInstanceType = createTypeAsyncAction(
  'UPDATE_NODE_INSTANCE_TYPE',
  async (args: MutationUpdateNodeInstanceTypeArgs) => {
    const client = new MemberGApiClient({});
    return client.updateNodeInstanceType(args);
  },
);

export const updateBlockExplorerInstanceType = createTypeAsyncAction(
  'UPDATE_BLOCK_EXP_INSTANCE_TYPE',
  async (args: MutationUpdateBlockExplorerInstanceTypeArgs) => {
    const client = new MemberGApiClient({});
    return client.updateBlockExplorerInstanceType(args);
  },
);

export const getGenesisJson = createTypeAsyncAction(
  'GET_GENESIS_JSON',
  async (args: QueryGetGenesisJsonArgs) => {
    const client = new MemberGApiClient({});
    return client.getGenesisJson(args);
  },
);

export const getGenesisJsonInExplorer = createTypeAsyncAction(
  'GET_GENESIS_JSON',
  async (args: QueryGetGenesisJsonInExplorerArgs) => {
    const client = new MemberGApiClient({});
    return client.getGenesisJsonInExplorer(args);
  },
);

export const sendProposal = createTypeAsyncAction(
  'SEND_PROPOSAL',
  async (args: MutationSendProposalArgs) => {
    const client = new MemberGApiClient({});
    return client.sendProposal(args);
  },
);

export const discardProposal = createTypeAsyncAction(
  'DISCARD_PROPOSAL',
  async (args: MutationDiscardProposalArgs) => {
    const client = new MemberGApiClient({});
    return client.discardProposal(args);
  },
);

export const listProposalHistory = createTypeAsyncAction(
  'LIST_PROPOSAL_HISTORY',
  async (args: QueryListProposalHistoryArgs, store: IStore) => {
    const { listProposalHistory } = store.appState;
    const client = new MemberGApiClient({});
    return client.listProposalHistory({
      ...args,
      page: {
        pageIndex: listProposalHistory.pageInfo.pageIndex,
        pageSize: listProposalHistory.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...listProposalHistory.filter,
        ...args.filter,
      },
    });
  },
);

export const updateExternalClef = createTypeAsyncAction(
  'UPDATE_EXTERNAL_CLEF',
  async (args: MutationUpdateExternalClefArgs) => {
    const client = new MemberGApiClient({});
    return client.updateExternalClef(args);
  },
);

export const updateBlockExplorerVersion = createTypeAsyncAction(
  'UPDATE_BLOCK_EXPLORER_VERSION',
  async (args: MutationUpdateBlockExplorerVersionArgs) => {
    const client = new MemberGApiClient({});
    return client.updateBlockExplorerVersion(args);
  },
);

export const setEndpointListener = createTypeAsyncAction(
  'SET_ENDPOINT_RESTRICTIONS',
  async (args: MutationSetEndpointListenerArgs) => {
    const client = new MemberGApiClient({});
    return client.setEndpointListener(args);
  },
);
